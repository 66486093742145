import React from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';

const CheckboxGroup = Checkbox.Group;
export const renderCheckboxGroupField = ({
  input,
  options,
  showMsg,
  change,
  meta: { touched, error }
}) => {
  let wraperClass = classNames(
    'input-group',
    {
      'has-errors':  touched && error
    }
  );

  return (
    <div className={wraperClass}>
      <CheckboxGroup
        options={options}
        value={input.value}
        onChange={(values) => change(input.name, values)} />
      {showMsg && touched && (error && <div className="invalid-feedback">{error}</div>)}
    </div>
  );
};
