export const postConstants = {
  INIT_POST: 'INIT_POST',
  SET_POST_DETAIL: 'SET_POST_DETAIL',
  GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
  UPDATE_POST_SUCCESS: 'UPDATE_POST_SUCCESS',
  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  UPDATE_POST_AVATAR_SUCCESS: 'UPDATE_POST_AVATAR_SUCCESS',
  GET_POST_DETAIL_SUCCESS: 'GET_POST_DETAIL_SUCCESS',
  UPDATE_POST_LOCAL: 'UPDATE_POST_LOCAL',
  SET_POST_NOT_FOUND: 'SET_POST_NOT_FOUND',
  SET_POST_PREVIEW: 'SET_POST_PREVIEW',
  GET_POSTS_STATUS_LIST_SUCCESS: 'GET_POSTS_STATUS_LIST_SUCCESS',
  GETTING_POST: 'GETTING_POST',
  GETTED_POST: 'GETTED_POST',
  FETCH_POST_STATUS: 'FETCH_POST_STATUS',
  SET_POST_FILTER: 'SET_POST_FILTER',
  GET_POST_HISTORIES_SUCCESS: 'GET_POST_HISTORIES_SUCCESS',
  RESET_POST_HISTORIES: 'RESET_POST_HISTORIES',
  INIT_POST_FILTER: {
    keyword: '',
    page: 1,
    per_page: 10,
    sort: {},
    filter: {}
  }
};
