import { departmentConstants } from '../constants';
import map from 'lodash/map';
import uniq from "lodash/uniq";
import filter from "lodash/filter";

const newDepartment = {
  id: null,
  name:  "",
  linkage:  "",
  description: "",
  mobile_show: false,
  additional: []
};

export default function reducer(state={
    departments: [],
    departmentDetail: {},
    isFetching: false,
    meta: {}
  }, action) {
    switch(action.type) {
      case (departmentConstants.FETCH_DEPARTMENT_STATUS): {
        const {isFetching} = action.payload;
        return {
          ...state,
          isFetching: isFetching,
        };
      }
      case (departmentConstants.GET_DEPARTMENTS_SUCCESS): {
        const {departments, meta, isExtend} = action.payload;
        if(isExtend) {
          return {
            ...state,
            departments: uniq([
              ...state.departments,
              ...departments
            ]),
            meta: meta
          };
        } else {
          return {
            ...state,
            departments: departments,
            meta: meta
          };
        }
      }

      case (departmentConstants.GET_DEPARTMENT_SUCCESS): {
        const {department} = action.payload;
        return {
          ...state,
          departmentDetail: department,
        };
      }

      case (departmentConstants.UPDATE_DEPARTMENT_SUCCESS): {
        const {department} = action.payload;
        if (department.ancestry) {
          if (parseInt(department.ancestry) === state.departmentDetail.id) {
            return {
              ...state,
              departmentDetail: {
                ...state.departmentDetail,
                children: map(state.departmentDetail.children, function(d) {
                  if(d.id == department.id) {
                    return department;
                  } else {
                    return d;
                  }
                })
              }
            };
          }

          return {
            ...state,
          };
        } else {
          return {
            ...state,
            departments: map(state.departments, function(d) {
              if(d.id == department.id) {
                return department;
              } else {
                return d;
              }
            }),
            departmentDetail: {
              ...state.departmentDetail,
              ...department
            },
          };
        }
      }

      case (departmentConstants.CREATE_DEPARTMENT_SUCCESS): {
        const {department} = action.payload;
        if (department.ancestry) {
          if (parseInt(department.ancestry) === state.departmentDetail.id) {
            return {
              ...state,
              departmentDetail: {
                ...state.departmentDetail,
                children: [...state.departmentDetail.children, department]
              }
            };
          }

          return {
            ...state,
          };
        } else {
          return {
            ...state,
            departments: [...state.departments, department],
          };
        }
      }

      case (departmentConstants.REMOVED_DEPARTMENT_AVATAR): {
        const {id} = action.payload;
        return {
          ...state,
          departments: map(state.departments, function(d) {
            if(d.id == id) {
              return {
                ...d,
                avatar_url: "",
                small_avatar_url: "",
              };
            } else {
              return d;
            }
          }),
          departmentDetail: {
            ...state.departmentDetail,
            avatar_url: "",
            small_avatar_url: "",
          },
        };
      }

      case (departmentConstants.INIT_DEPARTMENT): {
        return {
          ...state,
          departmentDetail: newDepartment,
        };
      }

      case (departmentConstants.ADD_DEPARTMENT_CATEGORIES): {
        const {id, categories} = action.payload;
        if(id === state.departmentDetail.id) {
          return {
            ...state,
            departmentDetail: {
              ...state.departmentDetail,
              categories: uniq([
                ...state.departmentDetail.categories,
                ...categories
              ])
            }
          };
        }

        return state;
      }

      case (departmentConstants.REMOVE_DEPARTMENT_CATEGORIES): {
        const {id, category} = action.payload;
        if(id === state.departmentDetail.id) {
          return {
            ...state,
            departmentDetail: {
              ...state.departmentDetail,
              categories: filter(state.departmentDetail.categories, (cat) => (cat.id !== category.id))
            }
          };
        }

        return state;
      }

      case (departmentConstants.UPDATE_DEP_CATEGORIES_ORDER_SUCCESS): {
        const {depId, categories} = action.payload;
        if(depId === state.departmentDetail.id) {
          return {
            ...state,
            departmentDetail: {
              ...state.departmentDetail,
              categories: categories
            }
          };
        }

        return state;
      }

      case (departmentConstants.UPDATE_DEP_DEPS_ORDER_SUCCESS): {
        const {depId, deps} = action.payload;
        if(depId === state.departmentDetail.id) {
          return {
            ...state,
            departmentDetail: {
              ...state.departmentDetail,
              children: deps
            }
          };
        } else if (depId === null) {
          return {
            ...state,
            departments: deps
          };
        }

        return state;
      }

      case (departmentConstants.DELETE_DEPARTMENT_SUCCESS): {
        const {department_id, ancestry} = action.payload;
        if(ancestry) {
          if (parseInt(ancestry) === state.departmentDetail.id) {
            return {
              ...state,
              departmentDetail: {
                ...state.departmentDetail,
                children: filter(state.departmentDetail.children, (d) => (d.id !== parseInt(department_id)))
              }
            };
          }
          return {
            ...state,
          };
        } else {
          let departmentDetail = state.departmentDetail;
          if (departmentDetail.id === parseInt(department_id)) {
            departmentDetail = {};
          }

          return {
            ...state,
            departments: filter(state.departments, (d) => (d.id !== parseInt(department_id))),
            departmentDetail: departmentDetail
          };
        }
      }

      default:
        return state;
    }
}
