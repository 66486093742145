import { connect } from 'react-redux';
import React from "react";

import {toggleMenuDrawer} from "../../../actions/common.actions";
import ButtoInstallApp from "./ButtoInstallApp";

@connect(() => {
  return {};
}, {toggleMenuDrawer})
class NavbarToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.toggleMenuDrawer({open: true});
  }

  render() {
    return (
      <nav className="nav">
        <div className="d-flex d-md-none d-lg-none">
          <ButtoInstallApp />
        </div>
        <a href="#" onClick={this.handleClick} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>
      </nav>
    );
  }
}

export default NavbarToggle;
