import React from 'react';
import classNames from 'classnames';
import { Input } from 'antd';

const { TextArea } = Input;

export const renderTextArea = ({
  input,
  label,
  readOnly,
  minRows,
  maxRows,
  showMsg,
  onKeyDown,
  className,
  prependIcon,
  appendIcon,
  meta: { touched, error }
}) => {
  let wraperClass = classNames(
    'input-group',
    {
      'has-errors':  touched && error
    }
  );
  let fieldClass = classNames(
    'form-control',
    className,
    {
      'is-invalid': touched && error
    }
  );
  return(
    <div className={wraperClass}>
      {
        prependIcon
      }
      <TextArea
        {...input}
        placeholder={label}
        autoSize={{ minRows: minRows, maxRows: maxRows || 3 }}
        className={fieldClass}
        onKeyDown={onKeyDown}
        readOnly={readOnly} />
      {
        appendIcon
      }
      {showMsg && touched && (error && <div className="invalid-feedback">{error}</div>)}
    </div>
  );
};
