import filter from 'lodash/filter';
import map from 'lodash/map';

import { postConstants } from '../constants';

const newPost = {
  id: null,
  title:  "",
  status: "draft",
  kind: "post",
  thumb: "",
  content: [],
  is_send_to_telegram: false,
  is_telegram_notify: false,
  is_no_crop: false,
  is_watermark: false,
  watermark: "1",
};
export default function reducer(state={
    isPostNotFound: false,
    isPostFetching: false,
    posts: [],
    postDetail: newPost,
    previewPost: null,
    postStatusList: [],
    isFetching: false,
    meta: {
      page: 1,
      per_page: 10,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      total: null,
    },
    postFilter: postConstants.INIT_POST_FILTER,
    postHistories: [],
  }, action) {
  switch(action.type) {
    case (postConstants.SET_POST_FILTER): {
      const {postFilter} = action.payload;
      return {
        ...state,
        postFilter: {
          ...state.postFilter,
          ...postFilter
        },
      };
    }
    case (postConstants.FETCH_POST_STATUS): {
      const {isFetching} = action.payload;
      return {
        ...state,
        isFetching: isFetching,
      };
    }
    case (postConstants.GETTING_POST): {
      return {
        ...state,
        isPostFetching: true,
      };
    }
    case (postConstants.GETTED_POST): {
      return {
        ...state,
        isPostFetching: false,
      };
    }

    case (postConstants.GET_POSTS_STATUS_LIST_SUCCESS): {
      const {postStatusList} = action.payload;
      return {
        ...state,
        postStatusList: postStatusList,
      };
    }

    case (postConstants.GET_POSTS_SUCCESS): {
      const {posts, meta} = action.payload;
      return {
        ...state,
        posts: posts,
        meta: meta,
      };
    }

    case (postConstants.DELETE_POST_SUCCESS): {
      const {post_id} = action.payload;
      return {
        ...state,
        posts: filter(state.posts, function(post) { return post_id != post.id; }),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    }
    case (postConstants.UPDATE_POST_SUCCESS): {
      const {post} = action.payload;
      let postDetail = state.postDetail;
      if(postDetail.id === post.id) {
        postDetail = post;
      }

      return {
        ...state,
        postDetail: postDetail,
        posts: map(state.posts, function(t) {
          if(t.id == post.id) {
            return post;
          } else {
            return t;
          }
        })
      };
    }

    case (postConstants.CREATE_POST_SUCCESS): {
      const {post} = action.payload;
      return {
        ...state,
        posts: [post, ...state.posts],
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        }
      };
    }

    case (postConstants.UPDATE_POST_AVATAR_SUCCESS): {
      const {id, avatar_url} = action.payload;
      let postDetail = state.postDetail;
      if(postDetail.id === id) {
        postDetail.avatar_url = avatar_url;
      }

      return {
        ...state,
        postDetail: postDetail,
        posts: map(state.posts, function(t) {
          if(t.id == id) {
            return {
              ...t,
              avatar_url: avatar_url
            };
          } else {
            return t;
          }
        })
      };
    }

    case (postConstants.SET_POST_DETAIL): {
      const {post} = action.payload;
      return {
        ...state,
        postDetail: post,
        isPostNotFound: false
      };
    }

    case (postConstants.SET_POST_NOT_FOUND): {
      const {isNotFound} = action.payload;
      return {
        ...state,
        isPostNotFound: isNotFound,
      };
    }

    case (postConstants.INIT_POST): {
      // const {department_id} = action.payload;
      const post = action.payload;
      return {
        ...state,
        postDetail: {
          ...newPost,
          ...post
        },
        isPostNotFound: false
      };
    }

    case (postConstants.SET_POST_PREVIEW): {
      const {post} = action.payload;
      return {
        ...state,
        previewPost: post,
      };
    }

    case (postConstants.RESET_POST_HISTORIES): {
      return {
        ...state,
        postHistories: [],
      };
    }

    case (postConstants.GET_POST_HISTORIES_SUCCESS): {
      const {histories} = action.payload;
      return {
        ...state,
        postHistories: histories,
      };
    }
  }

  return state;
}
