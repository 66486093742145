import { notificationConstants } from '../constants';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

export default function reducer(state={
    isFetching: false,
    notifications: [],
    unreadCount: 0,
    meta: {
      page: 1,
      per_page: 25,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      total: null,
    }
  }, action) {
  switch(action.type) {
    case (notificationConstants.GET_NOTIFICATIONS_COUNT_SUCCESS): {
      const {unread_count} = action.payload;
      return {
        ...state,
        unreadCount: unread_count
      };
    }

    case (notificationConstants.FETCHING_NOTIFICATIONS): {
      return {
        ...state,
        isFetching: true
      };
    }

    case (notificationConstants.FETCHED_NOTIFICATIONS): {
      return {
        ...state,
        isFetching: false
      };
    }

    case (notificationConstants.GET_NOTIFICATIONS_SUCCESS): {
      const {notifications, reset, meta} = action.payload;
      if(reset) {
        return {
          ...state,
          notifications: notifications,
          meta: meta,
        };
      }

      return {
        ...state,
        notifications: orderBy(uniqBy([...state.notifications, ...notifications], 'id'), 'created_at', 'desc'),
        meta: meta,
      };
    }

    case (notificationConstants.CLEAR_NOTIFICATIONS): {
      return {
        ...state,
        notifications: []
      };
    }
  }

  return state;
}
