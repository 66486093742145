import { connect } from 'react-redux';
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

@connect((store) => {
  return {
    isShowMenuDrawer: store.commonReducers.isShowMenuDrawer,
  };
}, {})
class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarNavItems: ""
    };
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const {isShowMenuDrawer} = this.props;
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      isShowMenuDrawer && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        <SidebarNavItems />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
