import { commonConstants } from './../constants';

export default function reducer(state={
    isShowMemberForm: false,
    isShowCategoriesForm: false,
    isFetching: false,
    isShowMenuDrawer: false,
    isShowAdSenseForm: false,
    isShowAdSenseDetail: false,
    isShowDepartmentForm: false,
    isShowDepartmentDetail: false,
    isShowModalPassword: false,
    isPreviewPost: false,
    isShowStationForm: false,
    isShowStructureForm: false,
    isShowSectionForm: false,
    isShowScheduleForm: false,
    isShowInstallApp: false,
    commonAlert: {}
  }, action) {
    switch(action.type) {
      case (commonConstants.COMMON_REQUESTING): {
        return {
          ...state,
          isFetching: true
        };
      }
      case (commonConstants.COMMON_REQUESTED): {
        return {
          ...state,
          isFetching: false
        };
      }

      case (commonConstants.HIDE_MEMBER_FORM_DRAWER): {
        return {
          ...state,
          isShowMemberForm: false,
        };
      }
      case (commonConstants.SHOW_MEMBER_FORM_DRAWER): {
        return {
          ...state,
          isShowMemberForm: true,
        };
      }

      case (commonConstants.TOGGLE_CATEGORIES_FORM): {
        const {open} = action.payload;
        return {
          ...state,
          isShowCategoriesForm: open,
        };
      }

      case (commonConstants.TOGGLE_SCHEDULE_FORM): {
        const {open} = action.payload;
        return {
          ...state,
          isShowScheduleForm: open,
        };
      }

      case (commonConstants.TOGGLE_MENU_DRAWER): {
        const {open} = action.payload;
        return {
          ...state,
          isShowMenuDrawer: open,
        };
      }

      case (commonConstants.TOGGLE_DEPARTMENT_FORM): {
        const {open} = action.payload;
        return {
          ...state,
          isShowDepartmentForm: open,
        };
      }

      case (commonConstants.TOGGLE_DEPARTMENT_DETAIL): {
        const {open} = action.payload;
        return {
          ...state,
          isShowDepartmentDetail: open,
        };
      }

      case (commonConstants.TOGGLE_ADSENSE_FORM): {
        const {open} = action.payload;
        return {
          ...state,
          isShowAdSenseForm: open,
        };
      }

      case (commonConstants.TOGGLE_ADSENSE_DETAIL): {
        const {open} = action.payload;
        return {
          ...state,
          isShowAdSenseDetail: open,
        };
      }

      case (commonConstants.SHOW_COMMON_ALERT): {
        const {alert} = action.payload;
        return {
          ...state,
          commonAlert: {
            ...state.commonAlert,
            ...alert
          }
        };
      }

      case (commonConstants.HIDE_COMMON_ALERT): {
        return {
          ...state,
          commonAlert: {}
        };
      }

      case (commonConstants.TOGGLE_MODAL_PASSWORD): {
        const {open} = action.payload;
        return {
          ...state,
          isShowModalPassword: open
        };
      }

      case (commonConstants.TOGGLE_PREVIEW_POST): {
        const {open} = action.payload;
        return {
          ...state,
          isPreviewPost: open
        };
      }

      case (commonConstants.TOGGLE_STATION_FORM_DRAWER): {
        const {open} = action.payload;
        return {
          ...state,
          isShowStationForm: open
        };
      }

      case (commonConstants.TOGGLE_STRUCTURE_FORM_DRAWER): {
        const {open} = action.payload;
        return {
          ...state,
          isShowStructureForm: open
        };
      }

      case (commonConstants.TOGGLE_SECTIONS_FORM): {
        const {open} = action.payload;
        return {
          ...state,
          isShowSectionForm: open
        };
      }

      case (commonConstants.TOGGLE_INSTALL_APP): {
        const {open} = action.payload;
        return {
          ...state,
          isShowInstallApp: open
        };
      }
    }
  return state;
}
