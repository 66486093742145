/*global setTimeout*/
import {reset} from 'redux-form';

import { commonConstants } from './../constants';

export function commonRequesting() {
  return function(dispatch) {
    dispatch({type: commonConstants.COMMON_REQUESTING, payload: null});
  };
}

export function commonRequested() {
  return function(dispatch) {
    dispatch({type: commonConstants.COMMON_REQUESTED, payload: null});
  };
}

export function showMemberForm() {
  return function(dispatch) {
    dispatch({type: commonConstants.SHOW_MEMBER_FORM_DRAWER, payload: null});
  };
}

export function closeMemberForm() {
  return function(dispatch) {
    dispatch({type: commonConstants.HIDE_MEMBER_FORM_DRAWER, payload: null});
    dispatch(reset('formMember'));
  };
}

export function toggleCategoryForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_CATEGORIES_FORM, payload: {open: options.open}});
    if(options.open) {
      dispatch(reset('formCategory'));
    }
  };
}

export function toggleScheduleForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_SCHEDULE_FORM, payload: {open: options.open}});
    if(options.open) {
      dispatch(reset('formSchedule'));
    }
  };
}

export function toggleMenuDrawer(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_MENU_DRAWER, payload: {open: options.open}});
  };
}

export function toggleDepartmentForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_DEPARTMENT_FORM, payload: {open: options.open}});
    if(options.open) {
      dispatch(reset('formDepartment'));
    }
  };
}

export function toggleDepartmentDetail(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_DEPARTMENT_DETAIL, payload: {open: options.open}});
  };
}

export function showAlert(alert) {
  return function(dispatch) {
    dispatch({type: commonConstants.SHOW_COMMON_ALERT, payload: {alert: {
      visible: true,
      ...alert
    }}});

    setTimeout(() =>{
      dispatch({type: commonConstants.HIDE_COMMON_ALERT, payload: {}});
    }, alert.delay || 5000);
  };
}

export function toggleAdSenseForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_ADSENSE_FORM, payload: {open: options.open}});
    if(options.open) {
      dispatch(reset('formAdSense'));
    }
  };
}

export function toggleAdSenseDetail(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_ADSENSE_DETAIL, payload: {open: options.open}});
  };
}


export function toggleModalPassword(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_MODAL_PASSWORD, payload: {open: options.open}});
  };
}

export function togglePreviewPost(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_PREVIEW_POST, payload: {open: options.open}});
  };
}

export function toggleStationForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_STATION_FORM_DRAWER, payload: options});
    if(options.open) {
      dispatch(reset('formStation'));
    }
  };
}

export function toggleStructureForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_STRUCTURE_FORM_DRAWER, payload: options});
  };
}

export function toggleSectionForm(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_SECTIONS_FORM, payload: {open: options.open}});
    if(options.open) {
      dispatch(reset('formSection'));
    }
  };
}

export function toggleInstallApp(options) {
  return function(dispatch) {
    dispatch({type: commonConstants.TOGGLE_INSTALL_APP, payload: options});
  };
}
