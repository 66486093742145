export const sectionConstants = {
  INIT_SECTION: 'INIT_SECTION',
  UPDATE_SECTION_SUCCESS: 'UPDATE_SECTION_SUCCESS',
  REMOVED_SECTION_AVATAR: 'REMOVED_SECTION_AVATAR',
  GET_SECTIONS_SUCCESS: 'GET_SECTIONS_SUCCESS',
  CREATE_SECTION_SUCCESS: 'CREATE_SECTION_SUCCESS',
  GET_SECTION_SUCCESS: 'GET_SECTION_SUCCESS',
  ADD_SECTION_DEPARTMENT: 'ADD_SECTION_DEPARTMENT',
  REMOVE_SECTION_DEPARTMENT: 'REMOVE_SECTION_DEPARTMENT',
  GET_SECTION_DEPARTMENT_SUCCESS: 'GET_SECTION_DEPARTMENT_SUCCESS',
  UPDATE_SECTION_DEPARTMENTS_ORDER_SUCCESS: 'UPDATE_SECTION_DEPARTMENTS_ORDER_SUCCESS',
  DELETE_SECTION_SUCCESS: 'DELETE_SECTION_SUCCESS',
  SET_SECTION_DETAIL: 'SET_SECTION_DETAIL',
  UPDATE_SECTIONS_ORDER_SUCCESS: 'UPDATE_SECTIONS_ORDER_SUCCESS'
};
