export const userConstants = {
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAIL: 'GET_CURRENT_USER_FAIL',

  LOADING_COMPLETE: 'LOADING_COMPLETE',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_SUCCESS_FAIL: 'LOGOUT_USER_SUCCESS_FAIL',
  REQUEST_FORGET_PASSWORD_SUCCESS: 'REQUEST_FORGET_PASSWORD_SUCCESS',
  REQUEST_FORGET_PASSWORD_FAIL: 'REQUEST_FORGET_PASSWORD_FAIL',
  RESET_IS_SENT_MAIL: 'RESET_IS_SENT_MAIL',

  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL'
};
