import { connect } from 'react-redux';
import React from "react";
import { Nav } from "shards-react";
import filter from "lodash/filter";
import includes from "lodash/includes";

import SidebarNavItem from "./SidebarNavItem";
import {sideItems} from "./../../../constants";
import {toggleMenuDrawer} from "../../../actions/common.actions";

@connect((store) => {
  return {
    currentStaff: store.currentUserReducers.user,
  };
}, {toggleMenuDrawer})
class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: sideItems
    };
  }

  onClick = () => {
    this.props.toggleMenuDrawer({open: false});
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const {currentStaff} = this.props;
    const { navItems } = this.state;
    const items = filter(navItems, (item) => (
      includes(item.allowRoles, currentStaff.role)));
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} onClick={this.onClick}/>
          ))}
        </Nav>
      </div>
    );
  }
}

export default SidebarNavItems;
