import { activityConstants } from '../constants';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

export default function reducer(state={
    isFetching: false,
    activities: [],
    meta: {
      page: 1,
      per_page: 25,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      total: null,
    }
  }, action) {
  switch(action.type) {
    case (activityConstants.FETCHING_ACTIVITIES): {
      return {
        ...state,
        isFetching: true
      };
    }

    case (activityConstants.FETCHED_ACTIVITIES): {
      return {
        ...state,
        isFetching: false
      };
    }

    case (activityConstants.GET_ACTIVITIES_SUCCESS): {
      const {activities, reset, meta} = action.payload;
      if(reset) {
        return {
          ...state,
          activities: activities,
          meta: meta,
        };
      }

      return {
        ...state,
        activities: orderBy(uniqBy([...state.activities, ...activities], 'id'), 'created_at', 'desc'),
        meta: meta,
      };
    }

    case (activityConstants.CLEAR_ACTIVITIES): {
      return {
        ...state,
        activities: []
      };
    }
  }

  return state;
}
