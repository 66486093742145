import filter from 'lodash/filter';

import { fileConstants } from './../constants';

export default function reducer(state={
    attach_files: [],
    meta: {
      page: 1,
      per_page: 20,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      total_record: null,
    }
  }, action) {
    switch(action.type) {
      case (fileConstants.GET_IMAGES_SUCCESS): {
        const {attach_files, meta} = action.payload;
        return {
          ...state,
          attach_files: attach_files,
          meta: meta,
        };
      }

      case (fileConstants.DELETE_IMAGE_SUCCESS): {
        const {attach_file_id} = action.payload;
        return {
          ...state,
          attach_files: filter(state.attach_files, function(attach_file) { return attach_file_id != attach_file.id; }),
          meta: {
            ...state.meta,
            total: state.meta.total - 1
          }
        };
      }

      case (fileConstants.UPLOAD_IMAGE_SUCCESS): {
        const {attach_file} = action.payload;
        return {
          ...state,
          attach_files: [attach_file, ...state.attach_files],
          meta: {
            ...state.meta,
            total: state.meta.total + 1
          }
        };
      }

      case (fileConstants.IMAGE_DELETED_SUCCESS): {
        const {attach_file_id} = action.payload;
        return {
          ...state,
          attach_files: filter(state.attach_files, function(attach_file) { return attach_file_id != attach_file.id; }),
          meta: {
            ...state.meta,
            total: state.meta.total - 1
          }
        };
      }
    }
  return state;
}
