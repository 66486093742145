/*global document console navigator window setTimeout*/
import React, {
  Component
}                               from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import 'moment-timezone';
import "@babel/polyfill";
import Moment from 'moment';
import 'moment/locale/km';

// import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "./libs/shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import 'react-image-lightbox/style.css';
import './../stylesheets/App.scss';
import configureStore from './store/configureStore';
import AppRouter from './AppRouter';
import { loginWithToken } from './actions/auth.actions';
import { toggleInstallApp } from './actions/common.actions';

Moment.locale('km');
const initialState = {};
export const {store} = configureStore(initialState);

store.dispatch(loginWithToken());

export default class Root extends Component {
  render() {
    return(
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}

var application = document.getElementById("root");
if(application) {
  ReactDOM.render(<Root></Root>, application);
}


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
       if (registration.waiting) {
         registration.waiting.postMessage({action: 'skipWaiting'});
       }
       registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                registration.waiting.postMessage({action: 'skipWaiting'});
              }
            }
          };
       };
       console.log('SW registered: ', registration);
     }).catch(registrationError => {
       console.log('SW registration failed: ', registrationError);
     });
  });

  // TODO add this to auto update when new code deploy
  // serviceWorker.register({
  //   onWaiting: function(worker) {
  //     // store.dispatch(toggleNewVersion({isNewVersion: true}));
  //     worker.waiting.postMessage({action: 'skipWaiting'});
  //   },
  //   onUpdate: function (worker) {
  //     worker.waiting.postMessage({action: 'skipWaiting'});
  //   },
  //   onSuccess: function(args) {}
  // });


  window.addEventListener('beforeinstallprompt', (event) => {
    console.log('👍', 'beforeinstallprompt');
    // Stash the event so it can be triggered later.
    event.preventDefault();
    window.deferredPrompt = event;

    // trigger install display
    setTimeout(function() {
      console.log('isShowInstallBtn');
      if(window.deferredPrompt) {
        store.dispatch(toggleInstallApp({open: true}));
      }
    }, 3000);
  });

  window.addEventListener('appinstalled', () => {
      // No need display install app
    console.log('👍', 'appinstalled');
    window.deferredPrompt = null;
    store.dispatch(toggleInstallApp({show: false}));
  });
}
