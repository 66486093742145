import { reportConstants } from '../constants';

export default function reducer(state={
    report: {}
  }, action) {
  switch(action.type) {
    case (reportConstants.GET_REPORT_SUCCESS): {
      const {report} = action.payload;
      return {
        ...state,
        report: report,
      };
    }
  }

  return state;
}
