import React from 'react';
import { FormCheckbox } from "shards-react";

export const renderCheckbox = (props) => {
  const {
    input,
    label,
    inline,
    meta: { touched, error }
  } = props;
  return(<FormCheckbox
      checked={input.value}
      {...input}
      onChange={() => input.onChange(!input.value)}
      inline={inline}
      invalid={touched && error}
    >
      {label}
    </FormCheckbox>
  );
};
