import axios from "axios";
import objectToFormData from "object-to-formdata";

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.image) {
    data.set('ads_image', params.image);
    // data.set('image', params.image);
  } else {
    data.delete('image');
    data.delete('ads_image');
  }
  // it might pass large data
  data.delete('image_url');

  return [data, config];
}


export const adSenseService = {
  getAdSense,
  getAdSenses,
  createAdSense,
  updateAdSense,
  deleteAdSense,
  removeImage
};

function getAdSense(id, options) {
  return axios.get("/v1/adsenses/" + id,
    {params: { ...options }});
}

function getAdSenses(options) {
  return axios.get("/v1/adsenses", {params: { ...options }});
}

function createAdSense(adSenseParams) {
  const formUser = getFormWithFileData(adSenseParams);

  return axios.post('/v1/adsenses',
                    formUser[0], formUser[1]);
}

function updateAdSense(adSenseParams) {
  const formUser = getFormWithFileData(adSenseParams);

  return axios.put('/v1/adsenses/' + adSenseParams.id,
                    formUser[0], formUser[1]);
}

function deleteAdSense(id) {
  return axios.delete('/v1/adsenses/' + id);
}

function removeImage(id) {
  return axios.delete('/v1/adsenses/' + id + '/remove_image');
}
