import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import isEmpty from 'lodash/isEmpty';

import {pareToColor} from './../../utils/pareToColor';

class UserAvatar extends Component {

  render() {
    const {className, avatar_url, name, size} = this.props;

    if(!isEmpty(avatar_url)) {
      return(<Avatar size={size} src={avatar_url} className={className} />);
    }

    return(
      <Avatar size={size}
              className={className}
              style={{color: '#FFF', backgroundColor: '#' + pareToColor(name)}}>
        {
          name != '' &&
          <span style={{fontSize: size / 2.5}}>{name[0].toUpperCase()}</span>
        }
      </Avatar>
    );
  }
}

UserAvatar.propTypes = {
  className: PropTypes.string,
  avatar_url: PropTypes.string,
  name: PropTypes.string,
};
export default UserAvatar;
