import React from "react";
import { connect } from 'react-redux';

import { createAdSense, updateAdSense } from '../../actions/banner.actions.js';
import BannerForm from './BannerForm';

@connect(() => {
  return {
  };
}, {createAdSense, updateAdSense})
class BannerDrawerForm extends React.Component {
  formSubmit = (data) => {
    if(data.id) {
      return this.props.updateAdSense(data);
    } else {
      return this.props.createAdSense(data);
    }
  }

  render() {
    return(
      <BannerForm placement='right'
                   onSubmit={this.formSubmit} />
    );
  }
}

export default BannerDrawerForm;
