import { defaultHeader } from './defaultHeader';
import {createAxiosResponseInterceptor} from './createAxiosResponseInterceptor';

export * from './file.service.js';
export * from './member.service.js';
export * from './category.service.js';
export * from './post.service.js';
export * from './department.service.js';
export * from './adsense.service.js';
export * from './activity.service.js';
export * from './notification.service.js';
export * from './dashboard.service.js';
export * from './user.service.js';
export * from './setting.service.js';
export * from './station.service.js';
export * from './structure.service.js';
export * from './section.service.js';
export * from './schedule.service.js';
export * from './report.service.js';

defaultHeader();
createAxiosResponseInterceptor();
