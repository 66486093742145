import map from 'lodash/map';
import filter from 'lodash/filter';

import { scheduleConstants } from '../constants';

const newSchedule = {
  id: null,
  name:  "",
  start_time:  "00:00",
  end_time:  "23:59",
  program_days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
  run_forever: false,
};
export default function reducer(state={
    schedules: [],
    scheduleDetail: {},
    meta: {
      page: 1,
      per_page: 10,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      pageSize: 0,
      total: null,
    }
  }, action) {
  switch(action.type) {
    case (scheduleConstants.GET_SCHEDULES_SUCCESS): {
      const {schedules, meta} = action.payload;
      return {
        ...state,
        schedules: schedules,
        meta: meta
      };
    }

    case (scheduleConstants.DELETE_SCHEDULE_SUCCESS): {
      const {schedule_id} = action.payload;
      return {
        ...state,
        schedules: filter(state.schedules, (cat) => (cat.id !== parseInt(schedule_id))),
      };
    }

    case (scheduleConstants.UPDATE_SCHEDULE_SUCCESS): {
      const {schedule} = action.payload;
      return {
        ...state,
        schedules: map(state.schedules, (cat) => {
          if(cat.id === schedule.id) {
            return {
              ...cat,
              ...schedule
            };
          }

          return cat;
        }),
      };
    }

    case (scheduleConstants.CREATE_SCHEDULE_SUCCESS): {
      const {schedule} = action.payload;
      return {
        ...state,
        schedules: [schedule, ...state.schedules],
      };
    }

    case (scheduleConstants.SET_SCHEDULE_DETAIL): {
      const {schedule} = action.payload;
      return {
        ...state,
        scheduleDetail: schedule,
      };
    }
    case (scheduleConstants.INIT_SCHEDULE): {
      return {
        ...state,
        scheduleDetail: newSchedule,
      };
    }
  }

  return state;
}
