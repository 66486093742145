import axios from "axios";

export const activityService = {
  fetchActivities,
};


function fetchActivities(options) {
  return axios.get("/v1/activities", {params: { ...options }});
}
