export const departmentConstants = {
  INIT_DEPARTMENT: 'INIT_DEPARTMENT',
  UPDATE_DEPARTMENT_SUCCESS: 'UPDATE_DEPARTMENT_SUCCESS',
  REMOVED_DEPARTMENT_AVATAR: 'REMOVED_DEPARTMENT_AVATAR',
  GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
  CREATE_DEPARTMENT_SUCCESS: 'CREATE_DEPARTMENT_SUCCESS',
  GET_DEPARTMENT_SUCCESS: 'GET_DEPARTMENT_SUCCESS',
  ADD_DEPARTMENT_CATEGORIES: 'ADD_DEPARTMENT_CATEGORIES',
  REMOVE_DEPARTMENT_CATEGORIES: 'REMOVE_DEPARTMENT_CATEGORIES',
  GET_DEPARTMENT_CATEGORIES_SUCCESS: 'GET_DEPARTMENT_CATEGORIES_SUCCESS',
  UPDATE_DEP_CATEGORIES_ORDER_SUCCESS: 'UPDATE_DEP_CATEGORIES_ORDER_SUCCESS',
  DELETE_DEPARTMENT_SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
  UPDATE_DEP_DEPS_ORDER_SUCCESS: 'UPDATE_DEP_DEPS_ORDER_SUCCESS',
  FETCH_DEPARTMENT_STATUS: 'FETCH_DEPARTMENT_STATUS'
};
