import React from "react";
import { Icon } from 'antd';
import includes from "lodash/includes";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import MomentLib from 'moment';

export function actionIcon(action, fontSize) {
  if(action === "update") {
    return(<Icon type="edit" style={{ fontSize: fontSize + 'px', color: '#17a2b8' }} />);
    } else if(includes(["create", "add_categories"], action)) {
    return(<Icon type="plus" style={{ fontSize: fontSize + 'px', color: '#28a745' }} />);
  } else if(includes(["remove_category", "destroy"], action)) {
    return(<Icon type="delete" style={{ fontSize: fontSize + 'px', color: '#dc3545' }} />);
  }

  return(null);
}

export function actionLink(activity) {
  if(includes(["destroy"], activity.action)) {
    return("");
  }

  if(!includes(["posts", "categories", "departments", "members", "adsenses"], activity.model)) {
    return("");
  }

  if(includes(["categories", "departments", "adsenses"], activity.model)) {
    return(`/${activity.model}`);
  }

  return(`/${activity.model}/${activity.trackable.id}`);
}

export function actionDescription(activity) {
  if(!includes(["posts", "categories", "departments", "members", "adsenses"], activity.model)) {
    return(null);
  }

  if(includes(["destroy"], activity.action)) {
    return(<span className="description">{activity.description}</span>);
  }

  if(includes(["categories", "departments", "adsenses"], activity.model)) {
    return(<Link to={`/${activity.model}`} className="description">{activity.description}</Link>);
  }

  return(<Link to={`/${activity.model}/${activity.trackable.id}`} className="description">{activity.description}</Link>);
}

export function actionTime(activity) {
  if(MomentLib(activity.created_at) < MomentLib().subtract(1, 'days')) {
    return(<Moment format="DD-MM-YYYY HH:MM:ss a">{activity.created_at}</Moment>);
  }
  return(<Moment fromNow>{activity.created_at}</Moment>);
}
