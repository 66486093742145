import { connect } from 'react-redux';
import React from "react";
import { Dropdown, DropdownToggle, Badge,
  DropdownMenu, DropdownItem, NavLink } from "shards-react";
import isEmpty from "lodash/isEmpty";
import { Icon } from 'antd';
import styled from 'styled-components';

import UserAvatar from '../../../../components/common/UserAvatar.jsx';
import {fetchNotifications, fetchUnreadNotifications} from "../../../../actions/notification.actions";
import {actionIcon, actionDescription, actionTime, actionLink} from "../../../../utils/commonActivity";
import { history } from '../../../../utils/history';

const DropdownContainer = styled(Dropdown)`
  .notifications-list {
    max-height: 350px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

  }
`;

@connect((store) => {
  return {
    notifications: store.notificationReducers.notifications,
    isFetching: store.notificationReducers.isFetching,
    meta: store.notificationReducers.meta,
    unreadCount: store.notificationReducers.unreadCount,
  };
}, {fetchNotifications, fetchUnreadNotifications})
class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    this.props.fetchUnreadNotifications();
  }

  toggleNotifications = () => {
    const {notifications} = this.props;
    if(isEmpty(notifications) && !this.state.visible) {
      this.props.fetchNotifications({per_page: 10});
    }

    this.setState({
      visible: !this.state.visible
    });
  }

  goToNotification = (notification) => {
    const notification_link = actionLink(notification);
    if(!isEmpty(notification_link)) {
      history.push(notification_link);
    }
  }

  loadMoreNotification = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {meta} = this.props;
    if(meta && meta.next_page) {
      this.props.fetchNotifications({per_page: meta.per_page, page: meta.next_page});
    }
  }

  gotoOwner = () => {}

  render() {
    const {unreadCount, notifications, meta, isFetching} = this.props;
    const notificationsEL = notifications.map((notification) =>(
      <DropdownItem key={notification.id} onClick={() => this.goToNotification(notification)}>
        <div className="media">
          <div onClick={(e) => this.gotoOwner(e)}>
            <UserAvatar size={30}
                        avatar_url={notification.owner.small_avatar_url}
                        name={notification.owner.name || ''} />
          </div>
          <div className="media-body ml-2">
            <p className="mb-0 pb-0">
              <span className="mr-1 font-weight-bold"
                onClick={(e) => this.gotoOwner(e)}>
                {notification.owner.name}
              </span>
              <span className="mr-1">
                {notification.action}
              </span>
              <span className="">
                <span className="mr-1">
                  a {notification.model}
                </span>
                {
                  actionDescription(notification)
                }
              </span>
            </p>
            <p className="mb-0 pb-0">
              <span className="mr-1">{actionIcon(notification.action, 14)}</span>
              {actionTime(notification)}
            </p>
          </div>
        </div>
      </DropdownItem>
    ));

    return (
      <DropdownContainer className="border-right dropdown notifications"
        open={this.state.visible} toggle={this.toggleNotifications}>
        <DropdownToggle tag={NavLink} className="nav-link-icon text-center">
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            {
              unreadCount > 0 &&
              <Badge pill theme="danger">
                {unreadCount}
              </Badge>
            }
          </div>
        </DropdownToggle>
        <DropdownMenu className="notifications-list">
          {notificationsEL}
          {
            !isFetching && isEmpty(notifications) &&
            <DropdownItem>
              <div className="text-center w-100">
                No Notification
              </div>
            </DropdownItem>
          }
          {
            isFetching &&
            <DropdownItem>
              <div className="text-center w-100">
                <Icon type="loading" />
              </div>
            </DropdownItem>
          }
          {
            !isFetching && !isEmpty(notifications) &&
            meta && meta.next_page &&
            <button
              className="notification__all text-center text-primary dropdown-item"
              onClick={this.loadMoreNotification}>
              See more ...
            </button>
          }
        </DropdownMenu>
      </DropdownContainer>
    );
  }
}

export default Notifications;
