import axios from "axios";
import map from 'lodash/map';
import objectToFormData from "object-to-formdata";

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.logo) {
    // data.set('logo', params.logo);
    data.set('logo_image', params.logo);
  } else {
    data.delete('logo_image');
  }
  data.delete('logo');
  // it might pass large data
  data.delete('logo_url');

  return [data, config];
}

export const categoryService = {
  getCategory,
  getCategories,
  createCategory,
  updateCategory,
  updateCategoriesOrder,
  deleteCategory,
  removeLogo
};

function getCategory(id, options) {
  return axios.get("/v1/categories/" + id,
    {params: { ...options }});
}

function getCategories(options) {
  return axios.get("/v1/categories", {params: { ...options }});
}

function createCategory(categoryParams) {
  const formCategory = getFormWithFileData(categoryParams);
  return axios.post('/v1/categories', formCategory[0], formCategory[1]);
}

function updateCategory(categoryParams) {
  const formCategory = getFormWithFileData(categoryParams);
  return axios.put('/v1/categories/' + categoryParams.id, formCategory[0], formCategory[1]);
}

function updateCategoriesOrder(categoryParams) {
  const categoriesIds = map(categoryParams, (category) => category.id);
  return axios.put('/v1/categories/update_order', {ids: categoriesIds});
}

function deleteCategory(id) {
  return axios.delete('/v1/categories/' + id);
}

function removeLogo(id) {
  return axios.delete('/v1/categories/' + id +'/remove_category_logo');
}
