import React from "react";
import { Container, Row, Col } from "shards-react";
import styled from 'styled-components';

const AuthContainer = styled(Container)`
  background: #eee;//#f5f6f8;
  height: 100vh;
  .card-body {
    overflow: hidden;
    box-shadow: inset 0 4px 0 0 #007bff;
    border-radius: .625rem;
  }
`;
const AuthLayout = ({ children }) => (
  <AuthContainer fluid>
    <Row>
      <Col
        className="main-content p-0"
        sm="12"
        tag="main"
      >
        <Container fluid className="main-content-container h-100 px-4">
          <Row noGutters className="h-100">
            <Col className="auth-form mx-auto my-auto col-sm-7 col-md-5 col-lg-3">
              <div className="card">
                <div className="card-body">
                  {children}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  </AuthContainer>
);

export default AuthLayout;
