import React from 'react';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import moment from "moment";

const { RangePicker } = DatePicker;
export const renderDateRangeField = ({
  name_1,
  name_2,
  value_1,
  value_2,
  showMsg,
  change,
  meta: { touched, error }
}) => {
  let wraperClass = classNames(
    'input-group',
    {
      'has-errors':  touched && error
    }
  );

  let dateValus = [];
  if (value_1 && value_2) {
    dateValus = [moment(value_1, 'DD/MM/YYYY'), moment(value_2, 'DD/MM/YYYY')];
  }
  return(
    <div className={wraperClass}>
       <RangePicker
          onChange={(values, valuesStr) => {
            change(name_1, valuesStr[0]);
            change(name_2, valuesStr[1]);}}
          value={dateValus}
          format={'DD/MM/YYYY'}
          className="w-100"
        />
      {showMsg && touched && (error && <div className="invalid-feedback">{error}</div>)}
    </div>
  );
};
