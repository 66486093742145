import { connect } from 'react-redux';
import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink
} from "shards-react";

import UserAvatar from '../../../../components/common/UserAvatar.jsx';
import { logOut } from '../../../../actions/auth.actions';

@connect((store) => {
  return {
    currentStaff: store.currentUserReducers.user,
  };
}, {logOut})
class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.logOut();
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    const {currentStaff} = this.props;

    return (
      <Dropdown  open={this.state.visible} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <UserAvatar size={40}
                      avatar_url={currentStaff.small_avatar_url}
                      name={currentStaff.name || ''} />
          {" "}
          <span className="d-none d-md-inline-block">
            {currentStaff.name}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <Link to="/my-profile" className="text-secondary dropdown-item">
            <i className="material-icons">&#xE7FD;</i> Profile
          </Link>
          <DropdownItem divider />
          <a href="/logout" className="text-danger dropdown-item" onClick={this.handleLogout}>
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </a>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default UserActions;
