import { connect } from 'react-redux';
import React from "react";
import { Modal, ModalBody, ModalHeader } from "shards-react";

import { memberService } from '../../services';
import { updatePassword } from '../../actions/member.actions.js';
import { toggleModalPassword } from '../../actions/common.actions.js';
import PasswordForm from "./PasswordForm";

@connect((store) => {
  return {
    currentUser: store.currentUserReducers.user,
    memberDetail: store.membersReducers.memberDetail,
    isShowModalPassword: store.commonReducers.isShowModalPassword,
  };
}, {toggleModalPassword, updatePassword})
class MemberPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_paswword: '',
    };
  }

  toggle = () => {
    const { isShowModalPassword } = this.props;
    this.props.toggleModalPassword({open: !isShowModalPassword});
    this.setState({new_paswword: ''});
  }

  handleSubmit = (values) => {
    return this.props.updatePassword(values);
  }

  resetPassword = () => {
    const { memberDetail } = this.props;
    memberService.resetMemberPassword(memberDetail.id)
      .then((response) => {
        this.setState({new_paswword: response.data.password});
      }).catch(() => {
        this.setState({new_paswword: 'error'});
      });
  }

  render() {
    const { isShowModalPassword, currentUser, memberDetail } = this.props;
    const new_paswword = this.state.new_paswword;

    return (
      <div>
        <Modal open={isShowModalPassword} toggle={this.toggle}>
          <ModalHeader>Change your password</ModalHeader>
          <ModalBody>
            {
              currentUser.id === memberDetail.id &&
              <PasswordForm toggle={this.toggle} onSubmit={this.handleSubmit} />
            }
            {
              currentUser.id !== memberDetail.id && new_paswword === '' &&
              <div className="text-center">
                <button className="btn btn-primary btn-100" onClick={this.resetPassword}>
                  Reset Pasword
                </button>
              </div>
            }
            {
              currentUser.id !== memberDetail.id && new_paswword !== '' &&
              <div className="text-center">
                Password: {new_paswword}
              </div>
            }
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default MemberPasswordModal;
