export const scheduleConstants = {
  INIT_SCHEDULE: 'INIT_SCHEDULE',
  SET_SCHEDULE_DETAIL: 'SET_SCHEDULE_DETAIL',
  GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
  UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',
  CREATE_SCHEDULE_SUCCESS: 'CREATE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_AVATAR_SUCCESS: 'UPDATE_SCHEDULE_AVATAR_SUCCESS',
  GET_SCHEDULE_DETAIL_SUCCESS: 'GET_SCHEDULE_DETAIL_SUCCESS',
};
