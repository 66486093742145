import filter from 'lodash/filter';
import map from 'lodash/map';

import { memberConstants } from '../constants';

const newMember = {
  id: null,
  name:  "",
  sex:  "male",
  email: "",
  avatar: "",
  bio: "",
  additional: []
};
export default function reducer(state={
    isFetching: false,
    members: [],
    rolesList: [],
    memberDetail: {},
    meta: {
      page: 1,
      per_page: 10,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      total: null,
    }
  }, action) {
  switch(action.type) {
    case (memberConstants.FETCH_MEMBER_STATUS): {
      const {isFetching} = action.payload;
      return {
        ...state,
        isFetching: isFetching,
      };
    }
    case (memberConstants.GET_ROLES_LIST_SUCCESS): {
      const {rolesList} = action.payload;
      return {
        ...state,
        rolesList: rolesList,
      };
    }

    case (memberConstants.GET_MEMBERS_SUCCESS): {
      const {members, meta} = action.payload;
      return {
        ...state,
        members: members,
        meta: meta,
      };
    }

    case (memberConstants.DELETE_MEMBER_SUCCESS): {
      const {member_id} = action.payload;
      return {
        ...state,
        members: filter(state.members, function(member) { return member_id != member.id; }),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    }
    case (memberConstants.UPDATE_MEMBER_SUCCESS): {
      const {member} = action.payload;
      let memberDetail = state.memberDetail;
      if(memberDetail.id === member.id) {
        memberDetail = member;
      }

      return {
        ...state,
        memberDetail: memberDetail,
        members: map(state.members, function(t) {
          if(t.id == member.id) {
            return member;
          } else {
            return t;
          }
        })
      };
    }

    case (memberConstants.CREATE_MEMBER_SUCCESS): {
      const {member} = action.payload;
      return {
        ...state,
        members: [member, ...state.members],
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        }
      };
    }

    case (memberConstants.UPDATE_MEMBER_AVATAR_SUCCESS): {
      const {id, avatar_url} = action.payload;
      let memberDetail = state.memberDetail;
      if(memberDetail.id === id) {
        memberDetail.avatar_url = avatar_url;
      }

      return {
        ...state,
        memberDetail: memberDetail,
        members: map(state.members, function(t) {
          if(t.id == id) {
            return {
              ...t,
              avatar_url: avatar_url
            };
          } else {
            return t;
          }
        })
      };
    }

    case (memberConstants.SET_MEMBER_DETAIL): {
      const {member} = action.payload;
      return {
        ...state,
        memberDetail: member,
      };
    }
    case (memberConstants.INIT_MEMBER): {
      return {
        ...state,
        memberDetail: newMember,
      };
    }
  }

  return state;
}
