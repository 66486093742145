/*global console window*/
import React from "react";
import { connect } from 'react-redux';

import { toggleInstallApp } from './../../../actions/common.actions';

@connect((store) => {
  return {
    isShowInstallApp: store.commonReducers.isShowInstallApp
  };
}, {toggleInstallApp})
class ButtoInstallApp extends React.Component {
  handleClick = (e) => {
    e.preventDefault();

    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    promptEvent.userChoice.then((result) => {
      console.log('👍', 'userChoice', result);
      // prompt() can only be called once.
      window.deferredPrompt = null;
      // Hide the install button.
      this.props.toggleInstallApp({open: false});
    });
  }


  render() {
    if(!this.props.isShowInstallApp) {
      return(<div/>);
    }

    return(<a onClick={this.handleClick} className="btn-primary btn-install-app">Install App</a>);
  }
}

export default ButtoInstallApp;
