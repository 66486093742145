import axios from "axios";
import objectToFormData from "object-to-formdata";
import map from 'lodash/map';

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.logo) {
    data.set('logo', params.logo);
  } else {
    data.delete('logo');
  }
  // it might pass large data
  data.delete('logo_url');

  return [data, config];
}


export const settingService = {
  getSetting,
  updateSetting,
  removeLogo,
  addSidebarDepartments,
  removeSidebarDepartment,
  updateSidebarDepartmentsOrder,
  addMenuCategories,
  removeMenuCategory,
  updateMenuCategoriesOrder,
  removeCache
};

function getSetting() {
  return axios.get("/v1/settings/" + 1234);
}

function updateSetting(settingParams) {
  const formSetting = getFormWithFileData(settingParams);

  return axios.put('/v1/settings/' + settingParams.id,
                    formSetting[0], formSetting[1]);
}

function removeLogo(id) {
  return axios.delete('/v1/settings/' + id + '/remove_logo');
}

function addSidebarDepartments(sectionParams) {
  const departmentIds = map(sectionParams.departments, (dep) => (dep.id));
  return axios.post('/v1/settings/' + sectionParams.id + '/add_departments', {department_ids: departmentIds});
}

function removeSidebarDepartment(sectionParams) {
  const departmentId = sectionParams.department.id;
  return axios.delete('/v1/settings/' + sectionParams.id + '/remove_department?department_id=' + departmentId);
}

function updateSidebarDepartmentsOrder(id, departmentsParams) {
  const departmentsIds = map(departmentsParams, (department) => department.id);
  return axios.put("/v1/settings/" + id + "/departments_update_order", {ids: departmentsIds});
}

function addMenuCategories(sectionParams) {
  const categoryIds = map(sectionParams.categories, (cat) => (cat.id));
  return axios.post('/v1/settings/' + sectionParams.id + '/add_categories', {category_ids: categoryIds});
}

function removeMenuCategory(sectionParams) {
  const categoryId = sectionParams.category.id;
  return axios.delete('/v1/settings/' + sectionParams.id + '/remove_category?category_id=' + categoryId);
}

function updateMenuCategoriesOrder(id, categoriesParams) {
  const categoryIds = map(categoriesParams, (cat) => cat.id);
  return axios.put("/v1/settings/" + id + "/categories_update_order", {ids: categoryIds});
}

function removeCache() {
  return axios.post('/v1/settings/remove_cache');
}
