/*global console location*/
import { SubmissionError } from 'redux-form';

import { history } from '../utils/history';
import { adSenseService } from '../services';
import { adSenseConstants } from '../constants';
import {commonRequesting, commonRequested, toggleAdSenseForm } from './common.actions';


export function fetchBanners(options) {
  return function(dispatch) {
    dispatch(commonRequesting());
    adSenseService.getAdSenses(options)
      .then((response) => {
        dispatch({type: adSenseConstants.GET_ADSENSES_SUCCESS, payload: response.data});
        dispatch(commonRequested());
      })
      .catch((err) => {
        console.log(err);
        dispatch(commonRequested());
      });
  };
}

export function initAdSense() {
  return function(dispatch) {
    dispatch({type: adSenseConstants.INIT_ADSENSE, payload: null});
  };
}

export function deleteAdSense(adsenseId) {
  return function(dispatch) {
    adSenseService.deleteAdSense(adsenseId)
      .then((response) => {
        dispatch({type: adSenseConstants.DELETE_ADSENSE_SUCCESS, payload: response.data});
        if(location.pathname != '/banners') {
          history.push('/banners');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function setAdSenseDetail(adsense) {
  return function(dispatch) {
    dispatch({type: adSenseConstants.SET_ADSENSE_DETAIL, payload: {adsense: adsense}});
  };
}

export function createAdSense(adsenseParams) {
  return function(dispatch) {
    return adSenseService.createAdSense(adsenseParams)
      .then((response) => {
        dispatch({type: adSenseConstants.CREATE_ADSENSE_SUCCESS, payload: response.data});
        dispatch(toggleAdSenseForm({open: false}));
      })
      .catch((err) => {
        throw new SubmissionError({...err.response.data.errors});
      });
  };
}

export function updateAdSense(adsenseParams) {
  return function(dispatch) {
    return adSenseService.updateAdSense(adsenseParams)
      .then((response) => {
        dispatch({type: adSenseConstants.UPDATE_ADSENSE_SUCCESS, payload: response.data});
        dispatch(toggleAdSenseForm({open: false}));
      })
      .catch((err) => {
        throw new SubmissionError({...err.response.data.errors});
      });
  };
}

export function removeAdSenseAvatar(adsenseId) {
  return function(dispatch) {
    adSenseService.removeImage(adsenseId)
      .then((response) => {
        dispatch({type: adSenseConstants.UPDATE_ADSENSE_IMAGE_SUCCESS, payload: response.data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
