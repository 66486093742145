export const memberConstants = {
  INIT_MEMBER: 'INIT_MEMBER',
  SET_MEMBER_DETAIL: 'SET_MEMBER_DETAIL',
  GET_MEMBERS_SUCCESS: 'GET_MEMBERS_SUCCESS',
  UPDATE_MEMBER_SUCCESS: 'UPDATE_MEMBER_SUCCESS',
  DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
  CREATE_MEMBER_SUCCESS: 'CREATE_MEMBER_SUCCESS',
  UPDATE_MEMBER_AVATAR_SUCCESS: 'UPDATE_MEMBER_AVATAR_SUCCESS',
  GET_MEMBER_DETAIL_SUCCESS: 'GET_MEMBER_DETAIL_SUCCESS',
  GET_ROLES_LIST_SUCCESS: 'GET_ROLES_LIST_SUCCESS',
  FETCH_MEMBER_STATUS: 'FETCH_MEMBER_STATUS'
};
