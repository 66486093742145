export const stationConstants = {
  INIT_STATION: 'INIT_STATION',
  SET_STATION_DETAIL: 'SET_STATION_DETAIL',
  GET_STATIONS_SUCCESS: 'GET_STATIONS_SUCCESS',
  UPDATE_STATION_SUCCESS: 'UPDATE_STATION_SUCCESS',
  DELETE_STATION_SUCCESS: 'DELETE_STATION_SUCCESS',
  CREATE_STATION_SUCCESS: 'CREATE_STATION_SUCCESS',
  UPDATE_STATION_AVATAR_SUCCESS: 'UPDATE_STATION_AVATAR_SUCCESS',
  GET_STATION_DETAIL_SUCCESS: 'GET_STATION_DETAIL_SUCCESS',
  UPDATE_STATIONS_ORDER_SUCCESS: 'UPDATE_STATIONS_ORDER_SUCCESS'
};
