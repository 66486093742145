export const structureConstants = {
  INIT_STRUCTURE: 'INIT_STRUCTURE',
  SET_STRUCTURE_DETAIL: 'SET_STRUCTURE_DETAIL',
  GET_STRUCTURES_SUCCESS: 'GET_STRUCTURES_SUCCESS',
  UPDATE_STRUCTURE_SUCCESS: 'UPDATE_STRUCTURE_SUCCESS',
  DELETE_STRUCTURE_SUCCESS: 'DELETE_STRUCTURE_SUCCESS',
  CREATE_STRUCTURE_SUCCESS: 'CREATE_STRUCTURE_SUCCESS',
  UPDATE_STRUCTURE_AVATAR_SUCCESS: 'UPDATE_STRUCTURE_AVATAR_SUCCESS',
  GET_STRUCTURE_DETAIL_SUCCESS: 'GET_STRUCTURE_DETAIL_SUCCESS',
  UPDATE_STRUCTURES_ORDER_SUCCESS: 'UPDATE_STRUCTURES_ORDER_SUCCESS',
  TOGGLE_STRUCTURES_TREE: 'TOGGLE_STRUCTURES_TREE',
  UPDATED_STRUCTURES_TREE: 'UPDATED_STRUCTURES_TREE',
  GET_STRUCTURE_SUCCESS: 'GET_STRUCTURE_SUCCESS',
  GET_AVAILABLE_USERS_STRUCTURE: 'GET_AVAILABLE_USERS_STRUCTURE',
  ADD_STRUCTURE_USERS: 'ADD_STRUCTURE_USERS',
  REMOVE_STRUCTURE_USER: 'REMOVE_STRUCTURE_USER',
  REMOVED_STRUCTURE_LOGO: 'REMOVED_STRUCTURE_LOGO',
  UPDATE_STRUCTURE_USER_ORDER_SUCCESS: 'UPDATE_STRUCTURE_USER_ORDER_SUCCESS'
};
