import axios from "axios";
import map from 'lodash/map';
import objectToFormData from "object-to-formdata";

function getFormWithAvatarData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.avatar) {
    data.set('avatar_image', params.avatar);
  } else {
    data.delete('avatar');
    data.delete('avatar_image');
  }
  // it might pass large data
  data.delete('avatar_url');

  return [data, config];
}


export const departmentService = {
  getDepartment,
  getDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  removeAvatar,
  addDepartmentCategories,
  removeDepartmentCategories,
  getDepartmentCategories,
  updateCategoriesOrder,
  updateDepOrder
};

function getDepartment(id, options) {
  return axios.get("/v1/departments/" + id,
    {params: { ...options }});
}

function getDepartments(options) {
  return axios.get("/v1/departments", {params: { ...options }});
}

function createDepartment(departmentParams) {
  const formDepartment = getFormWithAvatarData(departmentParams);
  return axios.post('/v1/departments', formDepartment[0], formDepartment[1]);
}

function updateDepartment(departmentParams) {
  const formDepartment = getFormWithAvatarData(departmentParams);
  return axios.put('/v1/departments/' + departmentParams.id,
    formDepartment[0], formDepartment[1]);
}

function deleteDepartment(id) {
  return axios.delete('/v1/departments/' + id);
}

function removeAvatar(id) {
  return axios.delete('/v1/departments/' + id +'/remove_department_avatar');
}

function addDepartmentCategories(departmentParams) {
  const categoryIds = map(departmentParams.categories, (cat) => (cat.id));
  return axios.post('/v1/departments/' + departmentParams.id + '/add_categories', {category_ids: categoryIds});
}

function removeDepartmentCategories(departmentParams) {
  const categoryId = departmentParams.category.id;
  return axios.delete('/v1/departments/' + departmentParams.id + '/remove_category?category_id=' + categoryId);
}

function getDepartmentCategories(id, options) {
  return axios.get("/v1/departments/" + id + '/categories',
    {params: { ...options }});
}

function updateCategoriesOrder(id, categoriesParams) {
  const categoriesIds = map(categoriesParams, (category) => category.id);
  return axios.put("/v1/departments/" + id + "/categories_update_order", {ids: categoriesIds});
}

function updateDepOrder(subDepsParams) {
  const depIds = map(subDepsParams, (dep) => dep.id);
  return axios.put("/v1/departments/deps_update_order", {ids: depIds});
}
