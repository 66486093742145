import { settingConstants } from '../constants';
import uniq from "lodash/uniq";
import filter from "lodash/filter";

export default function reducer(state={
    setting: null,
    sidebarDetail: {
      departments: []
    },
    menuDetail: {
      categories: []
    }
  }, action) {
  switch(action.type) {
    case (settingConstants.GET_SETTING_SUCCESS): {
      const {setting, departments, categories} = action.payload;
      return {
        ...state,
        setting: setting,
        sidebarDetail: {
          departments: departments
        },
        menuDetail: {
          categories: categories
        }
      };
    }

    case (settingConstants.UPDATE_SETTING_SUCCESS): {
      const {setting} = action.payload;
      return {
        ...state,
        setting: setting,
      };
    }

    case (settingConstants.UPDATE_SETTING_LOGO_SUCCESS): {
      const {logo_url, small_logo_url} = action.payload;
      return {
        ...state,
        setting: {
          ...state.setting,
          logo_url: logo_url,
          small_logo_url: small_logo_url
        }
      };
    }

    case (settingConstants.ADD_SIDEBAR_DEPARTMENT): {
      const {departments} = action.payload;
      return {
        ...state,
        sidebarDetail: {
          departments: uniq([
            ...departments,
            ...state.sidebarDetail.departments,
          ])
        }
      };
    }

    case (settingConstants.REMOVE_SIDEBAR_DEPARTMENT): {
      const {department} = action.payload;
      return {
        ...state,
        sidebarDetail: {
          ...state.sidebarDetail,
          departments: filter(state.sidebarDetail.departments, (cat) => (cat.id !== department.id))
        }
      };
    }

   case (settingConstants.UPDATE_SIDEBAR_DEPARTMENTS_ORDER_SUCCESS): {
      const {departments} = action.payload;
      return {
        ...state,
        sidebarDetail: {
          ...state.sidebarDetail,
          departments: departments
        }
      };
    }

     case (settingConstants.ADD_MENU_CATEGORIES): {
      const {categories} = action.payload;
      return {
        ...state,
        menuDetail: {
          categories: uniq([
            ...categories,
            ...state.menuDetail.categories,
          ])
        }
      };
    }

    case (settingConstants.REMOVE_MENU_CATEGORY): {
      const {category} = action.payload;
      return {
        ...state,
        menuDetail: {
          ...state.menuDetail,
          categories: filter(state.menuDetail.categories, (cat) => (cat.id !== category.id))
        }
      };
    }

   case (settingConstants.UPDATE_MENU_CATEGORIES_ORDER_SUCCESS): {
      const {categories} = action.payload;
      return {
        ...state,
        menuDetail: {
          ...state.menuDetail,
          categories: categories
        }
      };
    }
  }
  return state;
}
