import { routerReducer }    from 'react-router-redux';
import { combineReducers }  from 'redux';
import { reducer as formReducer } from 'redux-form';

import currentUserReducers from './currentUserReducers';
import filesReducers from './filesReducers';
import commonReducers from './commonReducers';
import membersReducers from './membersReducers';
import departmentReducers from './departmentReducers';
import categoriesReducers from './categoriesReducers';
import postsReducers from './postsReducers';
import adSensesReducers from './adSensesReducers';
import dashboardReducers from './dashboardReducers';
import activityReducers from './activityReducers';
import notificationReducers from './notificationReducers';
import settingReducers from './settingReducers';
import stationsReducers from './stationsReducers';
import structuresReducers from './structuresReducers';
import metaReducers from './metaReducers';
import sectionReducers from './sectionReducers';
import scheduleReducers from './scheduleReducers';
import reportReducers from './reportReducers';

export const reducers = {
  currentUserReducers,
  membersReducers,
  filesReducers,
  departmentReducers,
  commonReducers,
  categoriesReducers,
  postsReducers,
  adSensesReducers,
  dashboardReducers,
  activityReducers,
  notificationReducers,
  settingReducers,
  stationsReducers,
  structuresReducers,
  metaReducers,
  sectionReducers,
  scheduleReducers,
  reportReducers,
  form: formReducer,
};

export default combineReducers({
  ...reducers,
  routing: routerReducer
});
