import {permissionConstants} from "./permission.constants";

export const sideItems = [
  {
    title: "Dashboard",
    to: "/",
    exact: true,
    htmlBefore: '<i class="material-icons">dashboard</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.normalPermission,
  },
  {
    title: "Posts",
    htmlBefore: '<i class="material-icons">featured_play_list</i>',
    to: "/posts",
    allowRoles: permissionConstants.normalPermission,
  },
  {
    title: "Media",
    to: "/media",
    htmlBefore: '<i class="material-icons">perm_media</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.normalPermission,
  },
  // {
  //   title: "Radios",
  //   htmlBefore: '<i class="material-icons">featured_play_list</i>',
  //   to: "/radios",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  // {
  //   title: "TV",
  //   htmlBefore: '<i class="material-icons">featured_play_list</i>',
  //   to: "/televisions",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  // {
  //   title: "Schedules",
  //   to: "/schedules",
  //   htmlBefore: '<i class="material-icons">event_note</i>',
  //   htmlAfter: "",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  {
    title: "Categories",
    to: "/categories",
    htmlBefore: '<i class="material-icons">list</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.adminPermission,
  },
  {
    title: "Departments",
    to: "/departments",
    htmlBefore: '<i class="material-icons">account_balance</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.adminPermission,
  },
  // {
  //   title: "Reports",
  //   to: "/reports",
  //   htmlBefore: '<i class="material-icons">assessment</i>',
  //   htmlAfter: "",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  // {
  //   title: "Sections",
  //   to: "/sections",
  //   htmlBefore: '<i class="material-icons">menu</i>',
  //   htmlAfter: "",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  {
    title: "Members",
    to: "/members",
    htmlBefore: '<i class="material-icons">people</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.adminPermission,
  },
  // {
  //   title: "Structures",
  //   to: "/structures",
  //   htmlBefore: '<i class="material-icons">account_tree</i>',
  //   htmlAfter: "",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  // {
  //   title: "Banners",
  //   to: "/banners",
  //   htmlBefore: '<i class="material-icons">tv</i>',
  //   htmlAfter: "",
  //   allowRoles: permissionConstants.adminPermission,
  // },
  {
    title: "Settings",
    to: "/settings",
    htmlBefore: '<i class="material-icons">settings</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.adminPermission,
  },
  {
    title: "My Profile",
    to: "/my-profile",
    htmlBefore: '<i class="material-icons">person</i>',
    htmlAfter: "",
    allowRoles: permissionConstants.normalPermission,
  },
];
