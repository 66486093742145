const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength15 = maxLength(15);
export const maxLength25 = maxLength(25);
export const maxLength250 = maxLength(250);
export const maxLength500 = maxLength(500);

const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength2 = minLength(2);
export const minLength6 = minLength(6);

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValue18 = minValue(18);
export const absValue = minValue(0);
