import map from 'lodash/map';
import filter from 'lodash/filter';

import { categoryConstants } from '../constants';

const newCategory = {
  id: null,
  name:  "",
  linkage:  "",
  logo: null,
  icon: "",
};
export default function reducer(state={
    categories: [],
    categoryDetail: {},
  }, action) {
  switch(action.type) {
    case (categoryConstants.GET_CATEGORIES_SUCCESS): {
      const {categories} = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }

    case (categoryConstants.DELETE_CATEGORY_SUCCESS): {
      const {category_id} = action.payload;
      return {
        ...state,
        categories: filter(state.categories, (cat) => (cat.id !== parseInt(category_id))),
      };
    }

    case (categoryConstants.UPDATE_CATEGORY_SUCCESS): {
      const {category} = action.payload;
      return {
        ...state,
        categories: map(state.categories, (cat) => {
          if(cat.id === category.id) {
            return {
              ...cat,
              ...category
            };
          }

          return cat;
        }),
      };
    }

    case (categoryConstants.UPDATE_CATEGORIES_ORDER_SUCCESS): {
      const {categories} = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }

    case (categoryConstants.CREATE_CATEGORY_SUCCESS): {
      const {category} = action.payload;
      return {
        ...state,
        categories: [category, ...state.categories],
      };
    }

    case (categoryConstants.SET_CATEGORY_DETAIL): {
      const {category} = action.payload;
      return {
        ...state,
        categoryDetail: category,
      };
    }
    case (categoryConstants.INIT_CATEGORY): {
      return {
        ...state,
        categoryDetail: newCategory,
      };
    }

    case (categoryConstants.REMOVED_CATEGORY_LOGO): {
      const {id} = action.payload;
      return {
        ...state,
        categories: map(state.categories, function(cat) {
          if(cat.id == id) {
            return {
              ...cat,
              logo_url: "",
              small_logo_url: "",
            };
          } else {
            return cat;
          }
        }),
        categoryDetail: {
          ...state.categoryDetail,
          logo_url: "",
          small_logo_url: "",
        },
      };
    }
  }

  return state;
}
