import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import filter from "lodash/filter";
import includes from "lodash/includes";

// import NotFoundPage from './screens/NotFoundPage';
import LoadingPage from './screens/LoadingPage';
import routes from "./routes";
import { history } from './utils/history';
import MemberPasswordModal from './components/users/MemberPasswordModal';
import BannerDrawerForm from './components/banners/BannerDrawerForm';
import Error404 from "./screens/Error404";
import CommonAlert from "./components/common/CommonAlert";

@connect((store) => {
  return {
    currentStaff: store.currentUserReducers.user,
    isOnboarding: store.currentUserReducers.isOnboarding,
    isAuthenticated: !isEmpty(store.currentUserReducers.user),
  };
}, {})
class AppRouter extends React.Component {
  componentDidMount() {
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }
  render() {
    const {isOnboarding, currentStaff} =  this.props;
    if(isOnboarding) {
      return(<LoadingPage/>);
    }

    const allowRoutes = filter(routes, (r) => (
      r.plublic || includes(r.allowRoles, currentStaff.role)));
    return(
      <Router history={history}>
        <div className="application-layout">
          <Suspense fallback={<LoadingPage/>}>
            <Switch>
              {allowRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={(props) => {
                      return (
                        <route.layout {...props}>
                          <CommonAlert />
                          <route.component {...props} />
                        </route.layout>
                      );
                    }}
                  />
                );
              })}
              <Route component={Error404} />
            </Switch>
          </Suspense>
        </div>
        <BannerDrawerForm />
        <MemberPasswordModal />
      </Router>
    );
  }
}

export default AppRouter;
