import React from 'react';
import { Drawer, Upload, Icon, message, Button } from 'antd';
import { connect } from 'react-redux';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import styled from 'styled-components';

import { toggleAdSenseForm } from '../../actions/common.actions.js';
import { removeAdSenseAvatar } from '../../actions/banner.actions.js';
import { renderField, renderTextArea } from '../fieldsRender';
import { required, urlValidator} from '../validators';
import { getBase64 } from '../../utils/getBase64';

const { Dragger } = Upload;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  .btn-manage-thumb {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

@connect((state) => {
  const selector = formValueSelector('formAdSense');
  return {
    bannerKindsList: state.adSensesReducers.bannerKindsList,
    initialValues: state.adSensesReducers.adsenseDetail,
    isShowAdSenseForm: state.commonReducers.isShowAdSenseForm,
    image_url: selector(state, 'image_url') || '',
    ad_type: selector(state, 'ad_type') || '',
  };
}, {toggleAdSenseForm, removeAdSenseAvatar})
@reduxForm({
  form: 'formAdSense',
  enableReinitialize: true,
})
class BannerForm extends React.Component {
  onClose = (event) => {
    event.preventDefault();
    this.props.toggleAdSenseForm({open: false});
  };

  beforeUpload = (file) => {
    const {change} = this.props;
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const isImage = includes(acceptedImageTypes, file.type);
    if (!isImage) {
      message.error('You can only upload image file!');
    }

    change('image', file);
    getBase64(file, imageUrl =>
      (change('image_url', imageUrl))
    );
    return false;
  }

  onRemoveImage = () => {
    const {change, initialValues, removeAdSenseAvatar} = this.props;
    change('image', null);
    change('image_url', '');
    if(initialValues && initialValues.id) {
      removeAdSenseAvatar(initialValues.id);
    }
  }

  kindsList = () => {
    const {ad_type, bannerKindsList} = this.props;
    if(ad_type === "instant_article") {
      return filter(bannerKindsList, (kind) => {
        return kind.instant_article;
      });
    }

    const web_type = ad_type === "web";

    return filter(bannerKindsList, (kind) => {
      return kind.all || kind.web === web_type;
    });
  }

  render() {
    const {placement, handleSubmit, isShowAdSenseForm, initialValues, invalid,
      pristine, submitting, image_url} = this.props;
    const isEdit = initialValues && initialValues.id;
    let title = "Create Banner";
    if(isEdit) {
      title = "Edit Banner";
    }

    return (
      <div>
        <Drawer
          title={title}
          width={500}
          onClose={this.onClose}
          visible={isShowAdSenseForm}
          placement={placement}
          zIndex={2005}
        >
          <form className="row" onSubmit={ handleSubmit }>
            <div className="col-12 mb-3 text-center">
              {
                isEmpty(image_url) &&
                <Dragger multiple={false}
                         showUploadList={false}
                         action=''
                         beforeUpload={this.beforeUpload}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
              }

              {
                !isEmpty(image_url) &&
                <ImageContainer>
                  <img src={image_url} alt="adsense-image" className="img-fluid" />
                  <div className="btn-manage-thumb">
                    <Button type="danger" shape="circle" icon="delete" onClick={this.onRemoveImage} />
                  </div>
                </ImageContainer>
              }
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="name" className="mb-1">
                  Name
                </label>
                <Field
                  name="name"
                  className="form-control"
                  label='Ex. the Ads'
                  component={renderField}
                  validate={[required]} />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label htmlFor="ad_type" className="mb-1">
                  Kind
                </label>
                <Field name="ad_type"
                  component="select"
                  className="form-control"
                  validate={[required]}>
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                  <option value="instant_article">Instant Article</option>
                </Field>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label htmlFor="kind" className="mb-1">
                  Kind
                </label>
                <Field name="kind"
                  component="select"
                  className="form-control"
                  validate={[required]}>
                  <option></option>
                  {
                    this.kindsList().map((kind) => <option key={kind.value} value={kind.value}>{kind.text}</option>)
                  }
                </Field>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label htmlFor="link_to" className="mb-1">
                  Link To
                </label>
                <Field
                  name="link_to"
                  className="form-control"
                  label='www.exmple.com'
                  component={renderField}
                  validate={[urlValidator]} />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label htmlFor="description" className="mb-1">
                  Description
                </label>
                <Field
                  name="description"
                  className="form-control"
                  label='Take note this'
                  component={renderTextArea}
                  minRows={3}/>
              </div>
            </div>

            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'left',
              }}
            >
              <button className="btn btn-primary mr-2" type="submit" disabled={invalid || pristine || submitting}>
                <span className="ml-2">
                  Submit
                </span>
              </button>
              <button className="btn btn-secondary" onClick={this.onClose}>
                Cancel
              </button>
            </div>
          </form>
        </Drawer>
      </div>
    );
  }
}


export default BannerForm;
