/*global console location setTimeout*/
import { SubmissionError } from 'redux-form';

import { history } from '../utils/history';
import { memberService } from '../services';
import { memberConstants } from '../constants';
import {commonRequesting, commonRequested,
  closeMemberForm, toggleModalPassword, showAlert } from './common.actions';

export function createMember(memberParams) {
  return function(dispatch) {
    return memberService.createMember(memberParams)
      .then((response) => {
        dispatch({type: memberConstants.CREATE_MEMBER_SUCCESS, payload: response.data});
        dispatch(closeMemberForm());
        history.push('/members/' + response.data.member.id);
      })
      .catch((err) => {
        throw new SubmissionError({...err.response.data.errors});
      });
  };
}

export function updateMember(memberParams) {
  return function(dispatch) {
    return memberService.updateMember(memberParams)
      .then((response) => {
        dispatch({type: memberConstants.UPDATE_MEMBER_SUCCESS, payload: response.data});
        dispatch(closeMemberForm());
        history.push('/members/' + response.data.member.id);
      })
      .catch((err) => {
        throw new SubmissionError({...err.response.data.errors});
      });
  };
}

export function updatePassword(memberParams) {
  return function(dispatch) {
    return memberService.updatePassword(memberParams)
      .then(() => {
        dispatch(toggleModalPassword({open: false}));
        dispatch(showAlert({
          text: 'Password updated',
          status: 'success'
        }));
        location.reload();
      })
      .catch((err) => {
        throw new SubmissionError({...err.response.data.errors});
      });
  };
}



export function fetchMembers(options) {
  return function(dispatch) {
    dispatch(commonRequesting());
    memberService.getMembers(options)
      .then((response) => {
        dispatch({type: memberConstants.GET_MEMBERS_SUCCESS, payload: response.data});
        dispatch(commonRequested());
      })
      .catch((err) => {
        console.log(err);
        dispatch(commonRequested());
      });
  };
}

export function fetchMember(memberId) {
  return function(dispatch) {
    dispatch(commonRequesting());
    dispatch({type: memberConstants.FETCH_MEMBER_STATUS, payload: {isFetching: true}});
    memberService.getMember(memberId)
      .then((response) => {
        dispatch({type: memberConstants.SET_MEMBER_DETAIL, payload: response.data});
        dispatch(commonRequested());
        dispatch({type: memberConstants.FETCH_MEMBER_STATUS, payload: {isFetching: false}});
      })
      .catch((err) => {
        console.log(err);
        dispatch(commonRequested());
        dispatch({type: memberConstants.FETCH_MEMBER_STATUS, payload: {isFetching: false}});
      });
  };
}

export function deleteMember(memberId, isListPage) {
  return function(dispatch) {
    memberService.deleteMember(memberId)
      .then((response) => {
        dispatch({type: memberConstants.DELETE_MEMBER_SUCCESS, payload: response.data});
        if(!isListPage) {
          history.push('/members');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function removeMemberAvatar(memberId) {
  return function(dispatch) {
    memberService.removeAvatar(memberId)
      .then((response) => {
        dispatch({type: memberConstants.UPDATE_MEMBER_AVATAR_SUCCESS, payload: response.data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function  uploadMemberAvatar() {}

export function initMember() {
  return function(dispatch) {
    dispatch({type: memberConstants.FETCH_MEMBER_STATUS, payload: {isFetching: true}});
    dispatch({type: memberConstants.INIT_MEMBER, payload: null});
    setTimeout(() => {
      dispatch({type: memberConstants.FETCH_MEMBER_STATUS, payload: {isFetching: false}});
    }, 500);
  };
}

export function setMemberDetail(member) {
  return function(dispatch) {
    dispatch({type: memberConstants.SET_MEMBER_DETAIL, payload: {member: member}});
  };
}
