import axios from "axios";
import objectToFormData from "object-to-formdata";

function getFormWithAvatarData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.avatar) {
    data.set('avatar_image', params.avatar);
  } else {
    data.delete('avatar');
    data.delete('avatar_image');
  }
  // it might pass large data
  data.delete('avatar_url');

  return [data, config];
}

export const memberService = {
  getMember,
  getMembers,
  createMember,
  updateMember,
  deleteMember,
  removeAvatar,
  updatePassword,
  resetMemberPassword
};

function getMember(id, options) {
  return axios.get("/v1/members/" + id,
    {params: { ...options }});
}

function getMembers(options) {
  return axios.get("/v1/members", {params: { ...options }});
}

function createMember(memberParams) {
  const formUser = getFormWithAvatarData(memberParams);

  return axios.post('/v1/members',
                    formUser[0], formUser[1]);
}

function updateMember(memberParams) {
  const formUser = getFormWithAvatarData(memberParams);

  return axios.put('/v1/members/' + memberParams.id,
                    formUser[0], formUser[1]);
}

function updatePassword(memberParams) {
  return axios.put('/v1/members/update_password', memberParams);
}

function resetMemberPassword(memberId) {
  return axios.put('/v1/members/reset_member_password', {id: memberId});
}

function deleteMember(id) {
  return axios.delete('/v1/members/' + id);
}

function removeAvatar(id) {
  return axios.delete('/v1/members/' + id + '/remove_avatar');
}
