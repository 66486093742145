import React from 'react';
import classNames from 'classnames';

export const renderField = ({
  input,
  label,
  type,
  readOnly,
  className,
  prependIcon,
  appendIcon,
  showMsg,
  tabIndex,
  meta: { touched, error }
}) => {
  let wraperClass = classNames(
    'input-group',
    {
      'has-errors':  touched && error
    }
  );
  let fieldClass = classNames(
    'form-control',
    className,
    {
      'is-invalid': touched && error
    }
  );
  return(<div className={wraperClass}>
      {
        prependIcon &&
        (<div className="input-group-prepend">
          <span className="input-group-text">{prependIcon}</span>
        </div>)
      }
      <input {...input}
        placeholder={label}
        type={type}
        tabIndex={tabIndex}
        className={fieldClass}
        readOnly={readOnly} />
      {
        appendIcon &&
        (<div className="input-group-append">
          <span className="input-group-text">{appendIcon}</span>
        </div>)
      }
      {showMsg && touched && (error && <span className="invalid-feedback text-left">{error}</span>)}
    </div>
  );
};

