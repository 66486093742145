import React from "react";
import { connect } from 'react-redux';
import { Container, Alert } from "shards-react";

@connect((store) => {
  return {
    commonAlert: store.commonReducers.commonAlert,
  };
}, {})
class CommonAlert extends React.Component {
  render() {
    const {commonAlert} = this.props;
    if(!commonAlert.visible) {
      return(<div/>);
    }

    return(
      <Container fluid className="px-0">
        <Alert className="mb-0" theme={commonAlert.status}>
          {commonAlert.text}
        </Alert>
      </Container>
    );
  }
}

export default CommonAlert;
