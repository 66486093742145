import filter from 'lodash/filter';
import map from 'lodash/map';

import { adSenseConstants } from '../constants';

const newAdSense = {
  id: null,
  title:  "",
  visible: true,
  ad_type: "web",
  kind:  "aside",
  lankage: "",
  description: "",
};
export default function reducer(state={
    isFetching: false,
    adsenses: [],
    bannerKindsList: [],
    adsenseDetail: {},
    meta: {
      page: 1,
      per_page: 10,
      next_page: null,
      prev_page: null,
      total_pages: 0,
      total: null,
    }
  }, action) {
  switch(action.type) {
    case (adSenseConstants.GET_BANNER_KINDS_LIST_SUCCESS): {
      const {bannerKindsList} = action.payload;
      return {
        ...state,
        bannerKindsList: bannerKindsList,
      };
    }

    case (adSenseConstants.GET_ADSENSES_SUCCESS): {
      const {adsenses, meta} = action.payload;
      return {
        ...state,
        adsenses: adsenses,
        meta: meta,
      };
    }

    case (adSenseConstants.DELETE_ADSENSE_SUCCESS): {
      const {adsense_id} = action.payload;
      return {
        ...state,
        adsenses: filter(state.adsenses, function(adsense) { return adsense_id != adsense.id; }),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    }
    case (adSenseConstants.UPDATE_ADSENSE_SUCCESS): {
      const {adsense} = action.payload;
      let adsenseDetail = state.adsenseDetail;
      if(adsenseDetail.id === adsense.id) {
        adsenseDetail = adsense;
      }

      return {
        ...state,
        adsenseDetail: adsenseDetail,
        adsenses: map(state.adsenses, function(t) {
          if(t.id == adsense.id) {
            return adsense;
          } else {
            return t;
          }
        })
      };
    }

    case (adSenseConstants.CREATE_ADSENSE_SUCCESS): {
      const {adsense} = action.payload;
      return {
        ...state,
        adsenses: [adsense, ...state.adsenses],
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        }
      };
    }

    case (adSenseConstants.UPDATE_ADSENSE_IMAGE_SUCCESS): {
      const {id, image_url, small_image_url} = action.payload;
      let adsenseDetail = state.adsenseDetail;
      if(adsenseDetail.id === id) {
        adsenseDetail.image_url = image_url;
        adsenseDetail.small_image_url = small_image_url;
      }

      return {
        ...state,
        adsenseDetail: adsenseDetail,
        adsenses: map(state.adsenses, function(t) {
          if(t.id == id) {
            return {
              ...t,
              image_url: image_url,
              small_image_url: small_image_url
            };
          } else {
            return t;
          }
        })
      };
    }

    case (adSenseConstants.SET_ADSENSE_DETAIL): {
      const {adsense} = action.payload;
      return {
        ...state,
        adsenseDetail: adsense,
      };
    }
    case (adSenseConstants.INIT_ADSENSE): {
      return {
        ...state,
        adsenseDetail: newAdSense,
      };
    }
  }

  return state;
}
