/*global sessionStorage*/
import cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export function setTokens(token, refresh_token) {
  cookie.remove('refresh_token');
  cookie.remove('token');
  cookie.set('refresh_token', refresh_token);
  cookie.set('token', token);
}

export function setToken(token) {
  cookie.set('token', token);
}

export function getAccessToken() {
  return cookie.get('token');
}

export function getRefreshToken() {
  return cookie.get('refresh_token');
}

export function removeTokens() {
  cookie.remove('refresh_token');
  cookie.remove('token');
}

export function getSessionUUID() {
  let sessionUUID = sessionStorage.getItem("uuid");
  if(sessionUUID) {
    return sessionUUID;
  }

  // generate uuid
  sessionUUID = uuidv4();
  sessionStorage.setItem("uuid", sessionUUID);
  return sessionUUID;
}
