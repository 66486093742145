import axios from "axios";

export const notificationService = {
  fetchNotifications,
  fetchUnreadNotifications
};


function fetchNotifications(options) {
  return axios.get("/v1/notifications", {params: { ...options }});
}

function fetchUnreadNotifications() {
  return axios.get("/v1/notifications/unread_count");
}
