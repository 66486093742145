import React from "react";
import { Container, Button } from "shards-react";

import { history } from '../utils/history';

class Error404 extends React.Component {
  goBack = () => {
    history.push('/');
  }

  render() {
    return(
      <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
          <div className="error__content">
            <h2>404</h2>
            <h3>Not Found!</h3>
            <p>The page you were looking for does not exist.</p>
            <Button pill onClick={this.goBack}>&larr; Go Back</Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default Error404;
