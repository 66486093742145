import axios from "axios";

export const userService = {
  login,
  logout,
  forgotPassword,
  getCurrentUser,
  changePassword,
  getStaffDepartmentsMembersCateogries
};

function login(params) {
  return axios.post("/v1/signin", params);
}

function logout() {
  return axios.delete("/v1/signout");
}

function getCurrentUser() {
  return axios.get("/v1/current_user");
}

function getStaffDepartmentsMembersCateogries() {
  return axios.get("/v1/departments_members_categories");
}

function forgotPassword(params) {
  return axios.post("/v1/forgot_password", params);
}

function changePassword(params) {
  return axios.post("/v1/reset_pasword", params);
}
