import { sectionConstants } from '../constants';
import map from 'lodash/map';
import uniq from "lodash/uniq";
import filter from "lodash/filter";

const newSection = {
  id: null,
  name:  "",
};

export default function reducer(state={
    sections: [],
    sectionDetail: {},
  }, action) {
    switch(action.type) {
      case (sectionConstants.GET_SECTIONS_SUCCESS): {
        const {sections} = action.payload;
        return {
          ...state,
          sections: sections,
        };
      }

      case (sectionConstants.GET_SECTION_SUCCESS): {
        const {section} = action.payload;
        return {
          ...state,
          sectionDetail: section,
        };
      }

      case (sectionConstants.UPDATE_SECTION_SUCCESS): {
        const {section} = action.payload;
        return {
          ...state,
          sections: map(state.sections, function(d) {
            if(d.id == section.id) {
              return section;
            } else {
              return d;
            }
          }),
          sectionDetail: {
            ...state.sectionDetail,
            ...section
          },
        };
      }

      case (sectionConstants.UPDATE_SECTIONS_ORDER_SUCCESS): {
        const {sections} = action.payload;
        return {
          ...state,
          sections: sections,
        };
      }

      case (sectionConstants.UPDATE_SECTION_DEPARTMENTS_ORDER_SUCCESS): {
        const {sectionId, departments} = action.payload;
        if(sectionId === state.sectionDetail.id) {
          return {
            ...state,
            sectionDetail: {
              ...state.sectionDetail,
              departments: departments
            }
          };
        }

        return state;
      }

      case (sectionConstants.CREATE_SECTION_SUCCESS): {
        const {section} = action.payload;
        return {
          ...state,
          sections: [section, ...state.sections],
        };
      }

      case (sectionConstants.REMOVED_SECTION_LOGO): {
        const {id} = action.payload;
        return {
          ...state,
          sections: map(state.sections, function(d) {
            if(d.id == id) {
              return {
                ...d,
                logo_url: "",
                small_logo_url: "",
              };
            } else {
              return d;
            }
          }),
          sectionDetail: {
            ...state.sectionDetail,
            logo_url: "",
            small_logo_url: "",
          },
        };
      }

      case (sectionConstants.INIT_SECTION): {
        return {
          ...state,
          sectionDetail: newSection,
        };
      }

      case (sectionConstants.SET_SECTION_DETAIL): {
        const {section} = action.payload;
        return {
          ...state,
          sectionDetail: section,
        };
      }

      case (sectionConstants.ADD_SECTION_DEPARTMENT): {
        const {id, departments} = action.payload;
        if(id === state.sectionDetail.id) {
          return {
            ...state,
            sectionDetail: {
              ...state.sectionDetail,
              departments: uniq([
                ...departments,
                ...state.sectionDetail.departments,
              ])
            }
          };
        }

        return state;
      }

      case (sectionConstants.REMOVE_SECTION_DEPARTMENT): {
        const {id, department} = action.payload;
        if(id === state.sectionDetail.id) {
          return {
            ...state,
            sectionDetail: {
              ...state.sectionDetail,
              departments: filter(state.sectionDetail.departments, (cat) => (cat.id !== department.id))
            }
          };
        }

        return state;
      }

      case (sectionConstants.DELETE_SECTION_SUCCESS): {
        const {section_id} = action.payload;
        let sectionDetail = state.sectionDetail;
        if (sectionDetail.id === parseInt(section_id)) {
          sectionDetail = {};
        }

        return {
          ...state,
          sections: filter(state.sections, (d) => (d.id !== parseInt(section_id))),
          sectionDetail: sectionDetail
        };
      }

      default:
        return state;
    }
}
