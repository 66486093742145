export const categoryConstants = {
  INIT_CATEGORY: 'INIT_CATEGORY',
  SET_CATEGORY_DETAIL: 'SET_CATEGORY_DETAIL',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_AVATAR_SUCCESS: 'UPDATE_CATEGORY_AVATAR_SUCCESS',
  GET_CATEGORY_DETAIL_SUCCESS: 'GET_CATEGORY_DETAIL_SUCCESS',
  UPDATE_CATEGORIES_ORDER_SUCCESS: 'UPDATE_CATEGORIES_ORDER_SUCCESS',
  REMOVED_CATEGORY_LOGO: 'REMOVED_CATEGORY_LOGO',
};
