export const commonConstants = {
  COMMON_REQUESTING: 'COMMON_REQUESTING',
  COMMON_REQUESTED: 'COMMON_REQUESTED',
  HIDE_MEMBER_FORM_DRAWER: 'HIDE_MEMBER_FORM_DRAWER',
  SHOW_MEMBER_FORM_DRAWER: 'SHOW_MEMBER_FORM_DRAWER',
  TOGGLE_CATEGORIES_FORM: 'TOGGLE_CATEGORIES_FORM',
  TOGGLE_MENU_DRAWER: 'TOGGLE_MENU_DRAWER',
  TOGGLE_DEPARTMENT_FORM: 'TOGGLE_DEPARTMENT_FORM',
  TOGGLE_DEPARTMENT_DETAIL: 'TOGGLE_DEPARTMENT_DETAIL',
  HIDE_COMMON_ALERT: 'HIDE_COMMON_ALERT',
  SHOW_COMMON_ALERT: 'SHOW_COMMON_ALERT',
  TOGGLE_ADSENSE_FORM: 'TOGGLE_ADSENSE_FORM',
  TOGGLE_ADSENSE_DETAIL: 'TOGGLE_ADSENSE_DETAIL',
  TOGGLE_MODAL_PASSWORD: 'TOGGLE_MODAL_PASSWORD',
  TOGGLE_PREVIEW_POST: 'TOGGLE_PREVIEW_POST',
  TOGGLE_STATION_FORM_DRAWER: 'TOGGLE_STATION_FORM_DRAWER',
  TOGGLE_STRUCTURE_FORM_DRAWER: 'TOGGLE_STRUCTURE_FORM_DRAWER',
  TOGGLE_SECTIONS_FORM: 'TOGGLE_SECTIONS_FORM',
  TOGGLE_SCHEDULE_FORM: 'TOGGLE_SCHEDULE_FORM',
  TOGGLE_INSTALL_APP: 'TOGGLE_INSTALL_APP'
};
