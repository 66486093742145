/*global FormData*/
import axios from "axios";

export const fileService = {
  getImages,
  uploadImage,
  deleteImage,
  uploadImageViaAnt,
};


function getImages(options) {
  return axios.get("/v1/attach_files", {params: { ...options }});
}

function uploadImage(imageFile) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  let data = new FormData();
  data.append('file', imageFile);

  return axios.post('/v1/attach_files', data, config);
}

function uploadImageViaAnt(file, onProgress) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: onProgress
  };
  let data = new FormData();
  data.append('file', file);

  return axios.post('/v1/attach_files', data, config);
}

function deleteImage(imageId) {
  return axios.delete('/v1/attach_files/' + imageId);
}
