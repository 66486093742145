import React from 'react';
import { FormRadio } from "shards-react";

export const renderRadio = ({
  input,
  label,
  inline,
  meta: { touched, error }
}) => (
  <FormRadio
    {...input}
    inline={inline}
    invalid={touched && error}
  >
    {label}
  </FormRadio>
);
