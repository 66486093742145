import { dashboardConstants } from './../constants';

export default function reducer(state={
    dashboardStates: [],
    isFetching: false,
  }, action) {
    switch(action.type) {
      case (dashboardConstants.GET_DASHBOARD_STATES_SUCCESS): {
        const {dashboard} = action.payload;
        return {
          ...state,
          dashboardStates: dashboard
        };
      }
    }
  return state;
}
