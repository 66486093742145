import { notificationService } from '../services';
import { notificationConstants } from '../constants';

export function fetchNotifications(options) {
  return function(dispatch) {
    dispatch({type: notificationConstants.FETCHING_NOTIFICATIONS, payload: null});
    notificationService.fetchNotifications(options)
      .then((response) => {
        dispatch({type: notificationConstants.GET_NOTIFICATIONS_SUCCESS, payload: {...response.data, reset: options.reset}});
        dispatch({type: notificationConstants.FETCHED_NOTIFICATIONS, payload: null});
      })
      .catch(() => {
        dispatch({type: notificationConstants.FETCHED_NOTIFICATIONS, payload: null});
      });
  };
}

export function clearActivities() {
  return function(dispatch) {
    dispatch({type: notificationConstants.CLEAR_NOTIFICATIONS, payload: null});
  };
}

export function fetchUnreadNotifications() {
  return function(dispatch) {
    notificationService.fetchUnreadNotifications()
      .then((response) => {
        dispatch({type: notificationConstants.GET_NOTIFICATIONS_COUNT_SUCCESS, payload: response.data});
      })
      .catch(() => {});
  };
}
