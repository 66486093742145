import {
  createStore,
  applyMiddleware,
  compose
}                               from 'redux';
import thunkMiddleware          from 'redux-thunk';
import reducer                  from '../reducers/reducers';

// createStore : enhancer
const enhancer = compose(
  applyMiddleware(thunkMiddleware)
);

export default function configureStore(initialState) {
  const store = createStore(reducer, initialState, enhancer);
  return {store};
}
