export const adSenseConstants = {
  INIT_ADSENSE: 'INIT_ADSENSE',
  SET_ADSENSE_DETAIL: 'SET_ADSENSE_DETAIL',
  GET_ADSENSES_SUCCESS: 'GET_ADSENSES_SUCCESS',
  UPDATE_ADSENSE_SUCCESS: 'UPDATE_ADSENSE_SUCCESS',
  DELETE_ADSENSE_SUCCESS: 'DELETE_ADSENSE_SUCCESS',
  CREATE_ADSENSE_SUCCESS: 'CREATE_ADSENSE_SUCCESS',
  UPDATE_ADSENSE_IMAGE_SUCCESS: 'UPDATE_ADSENSE_IMAGE_SUCCESS',
  GET_ADSENSE_DETAIL_SUCCESS: 'GET_ADSENSE_DETAIL_SUCCESS',
  GET_BANNER_KINDS_LIST_SUCCESS: 'GET_BANNER_KINDS_LIST_SUCCESS'
};
