import { metaConstants } from '../constants';

export default function reducer(state={
    departments: [],
    categories: [],
    members: [],
    specialCategories: [],
    postKindsList: [],
    postWatermarkList: [],
  }, action) {
  switch(action.type) {
    case (metaConstants.GET_META_DEPARTMENTS_SUCCESS): {
      const {departments} = action.payload;
      return {
        ...state,
        departments: departments,
      };
    }

    case (metaConstants.GET_META_CATEGORIES_SUCCESS): {
      const {categories} = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }

    case (metaConstants.GET_META_POSTS_STATUSES): {
      const {postStatusList} = action.payload;
      return {
        ...state,
        postStatusList: postStatusList,
      };
    }

    case (metaConstants.GET_META_POSTS_KINDS): {
      const {postKindsList} = action.payload;
      return {
        ...state,
        postKindsList: postKindsList,
      };
    }

    case (metaConstants.GET_META_POSTS_WATERMARK): {
      const {postWatermarkList} = action.payload;
      return {
        ...state,
        postWatermarkList: postWatermarkList,
      };
    }



    case (metaConstants.GET_META_MEMBERS_SUCCESS): {
      const {members} = action.payload;
      return {
        ...state,
        members: members,
      };
    }

    case (metaConstants.GET_META_SPECIAL_CATEGORIES_SUCCESS): {
      const {special_categories} = action.payload;
      return {
        ...state,
        specialCategories: special_categories,
      };
    }
  }
  return state;
}
