import map from 'lodash/map';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';

import { structureConstants } from '../constants';

const newstructure = {
  id: null,
  title:  "",
};
export default function reducer(state={
    structures: [],
    structureDetail: {},
    users: [],
    users_meta: {
      page: 1,
      per_page: 10,
      next_page: null,
      prev_page: null,
      pageSize: 0,
      total_pages: 0,
      total: null,
    }
  }, action) {
  switch(action.type) {
    case (structureConstants.GET_STRUCTURES_SUCCESS): {
      const {structures} = action.payload;
      return {
        ...state,
        structures: structures,
      };
    }

    case (structureConstants.GET_STRUCTURE_SUCCESS): {
      const {structure} = action.payload;
      return {
        ...state,
        structureDetail: structure,
      };
    }

    case (structureConstants.GET_AVAILABLE_USERS_STRUCTURE): {
      const {users, meta} = action.payload;
      return {
        ...state,
        users: users,
        users_meta: meta
      };
    }


    case (structureConstants.DELETE_STRUCTURE_SUCCESS): {
      const {structure_id, ancestry} = action.payload;
      if(ancestry) {
        const parent_ids = ancestry.split("/");
        let loop = (arrTree, parentIds, level) => {
          return map(arrTree, (struct) => {
            if(struct.id === parseInt(parentIds[level])) {
              if(level >= parentIds.length - 1) {
                return {
                  ...struct,
                  children: filter(struct.children, (cat) => (cat.id !== parseInt(structure_id)))
                };
              } else {
                return {
                  ...struct,
                  children: loop(struct.children, parentIds, level + 1)
                };
              }
            } else {
              return struct;
            }
          });
        };

        let structures = loop(state.structures, parent_ids, 0);

        return {
          ...state,
          structures: structures,
        };
      } else {
        return {
          ...state,
          structures: filter(state.structures, (cat) => (cat.id !== parseInt(structure_id))),
        };
      }
    }

    case (structureConstants.UPDATE_STRUCTURE_SUCCESS): {
      const {structure} = action.payload;
      if(structure.ancestry) {
        const parent_ids = structure.ancestry.split("/");
        let loop = (arrTree, parentIds, level) => {
          return map(arrTree, (struct) => {
            if(struct.id === parseInt(parentIds[level])) {
              if(level >= parentIds.length - 1) {
                return {
                  ...struct,
                  children: map(struct.children, (cat) => {
                    if(cat.id === structure.id) {
                      return {
                        ...cat,
                        ...structure
                      };
                    }

                    return cat;
                  })
                };
              } else {
                return {
                  ...struct,
                  children: loop(struct.children, parentIds, level + 1)
                };
              }
            } else {
              return struct;
            }
          });
        };

        let structures = loop(state.structures, parent_ids, 0);

        return {
          ...state,
          structures: structures,
          structureDetail: structure
        };
      } else {
        return {
          ...state,
          structures: map(state.structures, (cat) => {
            if(cat.id === structure.id) {
              return {
                ...cat,
                ...structure
              };
            }

            return cat;
          }),
          structureDetail: structure
        };
      }
    }

    case (structureConstants.CREATE_STRUCTURE_SUCCESS): {
      const {structure} = action.payload;
      if(structure.ancestry) {
        const parent_ids = structure.ancestry.split("/");
        let loop = (arrTree, parentIds, level) => {
          return map(arrTree, (struct) => {
            if(struct.id === parseInt(parentIds[level])) {
              if(level >= parentIds.length - 1) {
                return {
                  ...struct,
                  children: [...struct.children, structure]
                };
              } else {
                return {
                  ...struct,
                  children: loop(struct.children, parentIds, level + 1)
                };
              }
            } else {
              return struct;
            }
          });
        };

        let structures = loop(state.structures, parent_ids, 0);

        return {
          ...state,
          structures: structures,
        };
      } else {
        return {
          ...state,
          structures: [...state.structures, structure],
        };
      }
    }

    case (structureConstants.SET_STRUCTURE_DETAIL): {
      const {structure} = action.payload;
      return {
        ...state,
        structureDetail: structure,
      };
    }

    case (structureConstants.INIT_STRUCTURE): {
      const {data} = action.payload;
      return {
        ...state,
        structureDetail: {
          ...newstructure,
          ...data
        }
      };
    }

    case (structureConstants.TOGGLE_STRUCTURES_TREE): {
      const {treeData} = action.payload;
      return {
        ...state,
        structures: treeData
      };
    }

    case (structureConstants.ADD_STRUCTURE_USERS): {
      const {id, users} = action.payload;
      if(id === state.structureDetail.id) {
        return {
          ...state,
          structureDetail: {
            ...state.structureDetail,
            users: uniq([
              ...state.structureDetail.users,
              ...users
            ])
          }
        };
      }

      return state;
    }

    case (structureConstants.REMOVE_STRUCTURE_USER): {
      const {id, user} = action.payload;
      if(id === state.structureDetail.id) {
        return {
          ...state,
          structureDetail: {
            ...state.structureDetail,
            users: filter(state.structureDetail.users, (cat) => (cat.id !== user.id))
          }
        };
      }

      return state;
    }

    case (structureConstants.UPDATE_STRUCTURE_USER_ORDER_SUCCESS): {
        const {structureId, users} = action.payload;
        if(structureId === state.structureDetail.id) {
          return {
            ...state,
            structureDetail: {
              ...state.structureDetail,
              users: users
            }
          };
        }

        return state;
      }
  }

  return state;
}
