import filter from 'lodash/filter';
import map from 'lodash/map';

import { stationConstants } from '../constants';

const newStation = {
  id: null,
  name:  "",
  name_en:  "",
  url: "",
  avatar: "",
};
export default function reducer(state={
    isFetching: false,
    stations: [],
    statioinDetail: {},
    meta: {
      page: 1,
      per_page: 25,
    },
  }, action) {
  switch(action.type) {
    case (stationConstants.GET_STATIONS_SUCCESS): {
      const {stations, meta} = action.payload;
      return {
        ...state,
        stations: stations,
        meta: meta
      };
    }

    case (stationConstants.DELETE_STATION_SUCCESS): {
      const {station_id} = action.payload;
      return {
        ...state,
        stations: filter(state.stations, function(station) { return station_id != station.id; }),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    }
    case (stationConstants.UPDATE_STATION_SUCCESS): {
      const {station} = action.payload;
      let stationDetail = state.stationDetail;
      if(stationDetail.id === station.id) {
        stationDetail = station;
      }

      return {
        ...state,
        stationDetail: stationDetail,
        stations: map(state.stations, function(t) {
          if(t.id == station.id) {
            return station;
          } else {
            return t;
          }
        })
      };
    }

    case (stationConstants.CREATE_STATION_SUCCESS): {
      const {station} = action.payload;
      return {
        ...state,
        stations: [station, ...state.stations],
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        }
      };
    }

    case (stationConstants.UPDATE_STATION_AVATAR_SUCCESS): {
      const {id, avatar_url} = action.payload;
      let stationDetail = state.stationDetail;
      if(stationDetail.id === id) {
        stationDetail.avatar_url = avatar_url;
      }

      return {
        ...state,
        stationDetail: stationDetail,
        stations: map(state.stations, function(t) {
          if(t.id == id) {
            return {
              ...t,
              avatar_url: avatar_url
            };
          } else {
            return t;
          }
        })
      };
    }

    case (stationConstants.SET_STATION_DETAIL): {
      const {station} = action.payload;
      return {
        ...state,
        stationDetail: station,
      };
    }
    case (stationConstants.INIT_STATION): {
      return {
        ...state,
        stationDetail: newStation,
      };
    }

    case (stationConstants.UPDATE_STATIONS_ORDER_SUCCESS): {
      const {stations} = action.payload;
      return {
        ...state,
        stations: stations,
      };
    }
  }

  return state;
}
