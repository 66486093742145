import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { renderField } from '../fieldsRender';
import { required, minLength6} from '../validators';

@reduxForm({
  form: 'formPassword',
  enableReinitialize: true,
})
class PasswordForm extends React.Component {
  render() {
    const {handleSubmit, invalid, pristine, submitting, toggle} = this.props;

    return (
      <form onSubmit={ handleSubmit }>
        <div className="form-group">
          <label htmlFor="password" className="mb-1">
            Current Password
          </label>
          <Field
            name="current_password"
            type="password"
            className="form-control"
            label= ''
            component={renderField}
            validate={[required, minLength6]}/>
        </div>

        <div className="form-group">
          <label htmlFor="password" className="mb-1">
            New Password
          </label>
          <Field
            name="password"
            type="password"
            className="form-control"
            label= ''
            component={renderField}
            validate={[required, minLength6]}/>
        </div>

        <button className="btn btn-primary mr-2" type="submit" disabled={invalid || pristine || submitting}>
          <span className="ml-2">
            Update
          </span>
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </button>
      </form>
    );
  }
}


export default PasswordForm;
