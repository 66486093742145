export const settingConstants = {
  GET_SETTING_SUCCESS: 'GET_SETTING_SUCCESS',
  UPDATE_SETTING_SUCCESS: 'UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_LOGO_SUCCESS: 'UPDATE_SETTING_LOGO_SUCCESS',
  ADD_SIDEBAR_DEPARTMENT: 'ADD_SIDEBAR_DEPARTMENT',
  REMOVE_SIDEBAR_DEPARTMENT: 'REMOVE_SIDEBAR_DEPARTMENT',
  UPDATE_SIDEBAR_DEPARTMENTS_ORDER_SUCCESS: 'UPDATE_SIDEBAR_DEPARTMENTS_ORDER_SUCCESS',
  ADD_MENU_CATEGORIES: 'ADD_MENU_CATEGORIES',
  REMOVE_MENU_CATEGORY: 'REMOVE_MENU_CATEGORY',
  UPDATE_MENU_CATEGORIES_ORDER_SUCCESS: 'UPDATE_MENU_CATEGORIES_ORDER_SUCCESS'
};
